import React from 'react';

const EventSchedule = ({ event }) => {
  // Sort the schedule details in ascending order based on the date
  const sortedScheduleDetails = event.schedule_details?.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  // Check if there are any schedule details to render
  const hasScheduleDetails = sortedScheduleDetails && sortedScheduleDetails.length > 0;

  return (
    <>

      {hasScheduleDetails && (
        <section id='schedule' className='scroll-mt-32 pb-16'>
          <h5 className='font-extrabold tracking-wide pb-4'>Schedule</h5>
          <div className="lg:w-full">
            <div className="hs-accordion-group divide-gray-200 -m-4">
              {sortedScheduleDetails.map((item, index) => (
                <div key={index} className={`hs-accordion  ${index === 0 ? 'active' : ''} basis-1/2 pr-8 border-2 p-4 m-4`} id={`hs-basic-with-title-and-arrow-stretched-heading-${index}`}><button className="hs-accordion-toggle group inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-gray-800 transition hover:text-gray-500ay-400" aria-controls={`hs-basic-with-title-and-arrow-stretched-collapse-${index}`}>
                  <div className="flex flex-wrap justify-between w-full text-sm">
                    <div>Day {index + 1} - {item.title}</div>
                    <div>{new Date(item.date).toLocaleString('en-US', {
                      day: '2-digit',
                      month: 'short',
                      weekday: 'short',
                      timeZone: 'Asia/Kathmandu'
                    })}</div>
                  </div>

                  <svg className="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                  </svg>
                  <svg className="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                  </svg>
                </button>
                  <div id={`hs-basic-with-title-and-arrow-stretched-collapse-${index}`} className={` hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${index === 0 ? 'block' : 'hidden'}`} aria-labelledby={`hs-basic-with-title-and-arrow-stretched-heading-${index}`}>

                    {item.description ?
                      (<div className='[&>ul]:text-sm [&>p]:text-sm [&_a]:font-bold [&_a]:underline  [&>p]:leading-relaxed [&>ul]:leading-relaxed [&>ul]:list-disc [&>ul]:indent-2 py-4' dangerouslySetInnerHTML={{ __html: item.description }} />) : null}

                    {
                      (item.headline || item.distance_covered > 0 || parseFloat(item.elevation_gained) !== 0 || parseFloat(item.elevation_lost) !== 0 || parseFloat(item.maximum_elevation) !== 0 || item.checkpoints > 0 || item.gpx_file) && (
                        <div className='flex flex-col-reverse flex-wrap p-2'>
                          {item.headline && (
                            <div className='basis-full'>
                              <img className='w-full' src={item.headline} alt='routemap' />
                            </div>
                          )}

                          <div className="basis-full grid grid-cols-2 content-between mb-8 gap-y-4">
                            {item.distance_covered > 0 && (
                              <div>
                                <sub className='uppercase'>Distance Covered</sub>
                                <p>{item.distance_covered}KM</p>
                              </div>
                            )}

                            {(parseFloat(item.elevation_gained) !== 0 && parseFloat(item.elevation_lost) !== 0) && (
                              <div>
                                <sub className='uppercase'>Elevation Gain/Loss</sub>
                                <p>
                                  {parseFloat(item.elevation_gained).toFixed(0)}m / -{parseFloat(item.elevation_lost).toFixed(0)}m
                                </p>
                              </div>
                            )}

                            {parseFloat(item.maximum_elevation) !== 0 && (
                              <div>
                                <sub className='uppercase'>Maximum Elevation</sub>
                                <p>{parseFloat(item.maximum_elevation).toFixed(0)} ft</p>
                              </div>
                            )}

                            {item.checkpoints > 0 && (
                              <div>
                                <sub className='uppercase'>Checkpoints</sub>
                                <p>{item.checkpoints}</p>
                              </div>
                            )}

                            {item.gpx_file && (
                              <div className='col-span-full'>
                                <button className='text-center border-2 py-2 mt-4 duration-300 hover:bg-black hover:text-white'>
                                  <h6 className='uppercase'>Download GPX</h6>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    }


                    <table className='w-full text-sm'>
                      <thead></thead>
                      <tbody>
                        {item.schedule_json.map((scheduleItem) => (
                          <tr key={scheduleItem.id} className='flex py-1'>
                            <td className=' align-top w-24 text-left flex-none border-slate-500 '>
                              {
                                new Date(scheduleItem.time).toLocaleString('en-US', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: true,
                                  timeZone: 'Asia/Kathmandu' // Set the time zone to Nepal
                                })
                              }
                            </td>
                            <td className=' align-top shrink'>{scheduleItem.info}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default EventSchedule;
