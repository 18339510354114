import React, { useEffect, useState } from 'react';
import { ImageList, ImageListItem } from '@mui/material';
import SimpleGallery from './SimpleGallery';

const EventGallery = ({ gallery }) => {
  const [imageDimensions, setImageDimensions] = useState([]);

  useEffect(() => {
    const loadImageDimensions = async () => {
      const dimensions = await Promise.all(
        gallery.results.map(async (image) => {
          const img = new Image();
          img.src = image.src;
          await img.decode();
          return {
            largeURL: `${image.src}`,
            thumbnailURL: `${image.src}`,
            width: img.naturalWidth,
            height: img.naturalHeight,
          };
        })
      );
      setImageDimensions(dimensions.reverse()); // Reverse the array here
    };

    loadImageDimensions();
  }, [gallery.results]);

  // Conditionally render the div only when there are images
  return (
    <>
      {imageDimensions.length > 0 && (
        <div className='scroll-mt-32 pb-10' id="gallery">
          <SimpleGallery galleryID="event-gallery" images={imageDimensions} />
        </div>
      )}
    </>
  );
};

export default EventGallery;
