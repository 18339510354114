import { React, useEffect, useState } from "react";
import { get } from '../api/index';
import { useParams } from "react-router-dom";
import { TwitterShareButton, FacebookShareButton, LinkedinShareButton } from "react-share";

const BlogPage = () => {
    const { slug } = useParams();
    const [blog, setblog] = useState({});

    useEffect(() => {
        get(`content/blogs/${slug}/`).then((res) => {
            setblog(res);
        });
    }, [slug]);

    const shareUrl = window.location.href; // The URL you want to share
    const title = `Check this article: ` + blog.title; // The title of your blog post

    return (
        <div className="pb-10 font-custom">
            <div
                className="h-screen bg-fixed bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${blog.cover_image})` }}
            />
            <div className="max-w-3xl  px-4 pt-6 lg:pt-10 sm:px-6 lg:px-8 mx-auto py-10 shadow-md -translate-y-60  bg-white rounded-2xl" style={{ marginBottom: '-200px' }}>
                <div className="max-w-2xl">
                    {/*<!-- Avatar Media -->*/}
                    <div className="flex justify-between items-center mb-6">
                        <div className="flex w-full sm:items-center gap-x-5 sm:gap-x-3">
                            {blog.author_details &&
                                <div className="flex-shrink-0">
                                    <img className="h-12 w-12 rounded-full" src={blog?.author_details?.profile_image} alt="Image Description" />
                                </div>
                            }

                            <div className="grow">
                                <div className="grid sm:flex sm:justify-between sm:items-center gap-2">
                                    <div>
                                        {/*<!-- Tooltip -->*/}
                                        <div className=" inline-block">
                                            <div className="sm:mb-1 block text-left flex flex-col">

                                                {blog.author_details ? (
                                                    <span className="font-semibold text-gray-800">
                                                        {blog.author_details?.first_name} {blog.author_details?.last_name}
                                                    </span>
                                                ) : (
                                                    <span className="font-semibold text-gray-800">{blog.author_name}</span>
                                                )}
                                            </div>
                                        </div>

                                        <ul className="text-xs text-gray-500 py-0">
                                            {blog.author_details &&
                                                <li className="inline-block relative pr-6 last:pr-0 last-of-type:before:hidden before:absolute before:top-1/2 before:right-2 before:-translate-y-1/2 before:w-1 before:h-1 before:bg-gray-300 before:rounded-full ">
                                                    {blog.author_details.designation}
                                                </li>
                                            }

                                            <li className="inline-block relative pr-6 last:pr-0 last-of-type:before:hidden before:absolute before:top-1/2 before:right-2 before:-translate-y-1/2 before:w-1 before:h-1 before:bg-gray-300 before:rounded-full ">
                                                {new Date(blog.created_at).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', timeZone: 'Asia/Kathmandu' })}
                                            </li>
                                            <li className="inline-block relative pr-6 last:pr-0 last-of-type:before:hidden before:absolute before:top-1/2 before:right-2 before:-translate-y-1/2 before:w-1 before:h-1 before:bg-gray-300 before:rounded-full ">
                                                {blog.minutes_to_read} min read
                                            </li>
                                        </ul>
                                    </div>

                                    {/*<!-- Button Group -->*/}


                                    <FacebookShareButton url={shareUrl} title={title}>

                                        <button type="button" className="py-1.5 px-2.5 sm:py-2 sm:px-3 inline-flex justify-center items-center gap-x-1.5 sm:gap-x-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-xs sm:text-sm ">
                                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                            </svg>
                                            Share
                                        </button>

                                    </FacebookShareButton>
                                    {/*<!-- End Button Group -->*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<!-- End Avatar Media -->*/}

                    {/*<!-- Content -->*/}
                    <div className="space-y-5 md:space-y-8">
                        <div className="space-y-3">
                            <h2 className="text-2xl font-bold md:text-3xl mb-4">{blog.title}</h2>

                            <span className=" text-gray-800 [&_p]:text-sm [&_p]:mb-4 [&_figcaption]:text-sm [&_figcaption]:text-center [&_figcaption]:text-gray-500 [&_figcaption]:mb-4 [&_li]:pl-4 [&_ul]:list-disc [&_ul]:mb-4 [&_li]:text-sm [&_li]:mb-2 [&_img]:w-full [&_img]:mt-4 [&_img]:rounded-xl " dangerouslySetInnerHTML={{ __html: blog.content }} />
                        </div>
                    </div>
                    {/*<!-- End Content -->*/}
                </div>
            </div>
            {/*<!-- End Blog Article -->*/}

            {/*<!-- Sticky Share Group -->*/}
            <div className="sticky bottom-6 inset-x-0 text-center">
                <div className="inline-block bg-white shadow-md rounded-full py-3 px-4 ">
                    <div className="flex items-center gap-x-1.5">


                        {/*<!-- Button -->*/}
                        <div className="hs-dropdown relative inline-flex">
                            <button type="button" id="blog-article-share-dropdown" className="hs-dropdown-toggle flex items-center gap-x-2 text-sm text-gray-500 hover:text-gray-800 ">
                                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1h-2z" />
                                    <path fillRule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 1.707V10.5a.5.5 0 0 1-1 0V1.707L5.354 3.854a.5.5 0 1 1-.708-.708l3-3z" />
                                </svg>
                                Share
                            </button>
                            <div className="hs-dropdown-menu w-56 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden mb-1 z-10 bg-gray-900 shadow-md rounded-xl p-2 " aria-labelledby="blog-article-share-dropdown">
                                <a className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-400 hover:bg-white/10 focus:outline-none focus:ring-2 focus:ring-gray-400" href="#">
                                    <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                                        <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                                    </svg>
                                    Copy link
                                </a>
                                <div className="border-t border-gray-600 my-2"></div>
                                <TwitterShareButton url={shareUrl} title={title} via="TrailHimalayan">
                                    <button
                                        type="button"
                                        className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-400 hover:bg-white/10 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                    >
                                        <svg className="w-4 h-4" width="1200" height="1227" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white" />
                                        </svg>
                                        Share on X
                                    </button>
                                </TwitterShareButton>
                                <FacebookShareButton url={shareUrl} title={title} quote={title} hashtags="#HimalayanTrailRunning #FishtailRace #Fishtail100 #TrailRunning #Nepal">
                                    <button
                                        type="button"
                                        className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-400 hover:bg-white/10 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                    >
                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                        </svg>
                                        Share on Facebook
                                    </button>
                                </FacebookShareButton>
                                <LinkedinShareButton url={shareUrl} title={title}>
                                    <button
                                        type="button"
                                        className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-400 hover:bg-white/10 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                    >
                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                        </svg>
                                        Share on LinkedIn
                                    </button>
                                </LinkedinShareButton>
                            </div>
                        </div>
                        {/*<!-- Button -->*/}
                    </div>
                </div>
            </div>
            {/*<!-- End Sticky Share Group -->*/}
        </div>
    )
}

export default BlogPage;