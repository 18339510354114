import React, { useEffect, useState } from 'react';
import { images } from '../../../constants';
import { motion } from "framer-motion";
import "./EventRegistration.css"

const EventRegistration = ({ event }) => {
    const [daysUntilRegistrationOpens, setDaysUntilRegistrationOpens] = useState(null);

    useEffect(() => {
        if (event && event.open_registration_from) {
            const now = new Date().getTime();
            const openRegistrationTime = new Date(event.open_registration_from).getTime();
            if (now < openRegistrationTime) {
                const timeUntilRegistrationOpens = openRegistrationTime - now;
                const daysUntilOpens = Math.ceil(timeUntilRegistrationOpens / (1000 * 3600 * 24));
                setDaysUntilRegistrationOpens(daysUntilOpens);
            }
        }
    }, [event]);


    if (!event || !event?.category_details || !Array.isArray(event?.category_details)) {
        return <div>No event data available.</div>;
    }

    // Extracting prices for each category
    const nepaliPrices = event?.category_details.map((category) => category.nepali_price);
    const saarcPrices = event?.category_details.map((category) => category.saarc_price);
    const internationalPrices = event?.category_details.map((category) => category.international_price);

    // Finding the maximum and minimum values for each category
    const maxNepaliPrice = Math.max(...nepaliPrices);
    const minNepaliPrice = Math.min(...nepaliPrices);

    const maxSaarcPrice = Math.max(...saarcPrices);
    const minSaarcPrice = Math.min(...saarcPrices);

    const maxInternationalPrice = Math.max(...internationalPrices);
    const minInternationalPrice = Math.min(...internationalPrices);

    const registrationLink = `/events/${event.slug}/register`;

    return (
        <div className="sticky top-32 z-10 ">
            {event.is_registration_open ? (
                <>
                    <div className='border-2 rounded-sm p-8'>
                        <div className="">
                            <div>
                                <h5 className='font-bold'>{event.name}</h5>

                                <p className='text-sm'>{new Date(event.start_datetime).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', timeZone: 'Asia/Kathmandu' })}</p>
                            </div>
                            <p className='mt-4 mb-0 uppercase text-xs'>Starting Price</p>
                            <div className='mb-6 text-sm'>
                                <div className='grid grid-cols-2 gap-y-2 mb-2'>
                                    {maxNepaliPrice > 0 && minNepaliPrice > 0 && (
                                        <div>
                                            <small><strong>Nepali</strong></small>
                                            <p className='text-sm'>Rs. {Number(minNepaliPrice).toLocaleString('en-NP')}</p>
                                        </div>
                                    )}
                                    {maxSaarcPrice > 0 && minSaarcPrice > 0 && (
                                        <div>
                                            <small><strong>SAARC</strong></small>
                                            <p className='text-sm'>Rs. {Number(minSaarcPrice).toLocaleString('en-NP')}</p>
                                        </div>
                                    )}
                                    {maxInternationalPrice > 0 && minInternationalPrice > 0 && (
                                        <div>
                                            <small><strong>International</strong></small>
                                            <p className='text-sm'>Rs. {Number(minInternationalPrice).toLocaleString('en-NP')}</p>
                                        </div>
                                    )}
                                </div>
                                <a href="#pricing" className='text-xs underline'>Detailed Pricing</a>
                            </div>
                        </div>
                        <a href={registrationLink}>
                            <div className="w-full text-center rounded-md bg-red-600 hover:bg-red-700 ease-in duration-200 p-3 text-white font-semibold mt-5 uppercase text-sm"
                            >
                                Register
                            </div>
                        </a>
                    </div>
                    <div className='p-4 text-sm'>
                        <table  >
                            <tbody>
                                <tr >
                                    <td className='flex justify-center w-8 py-2'>
                                        <img src={images.deadline} className="w-4 fill-red-600" alt="Ticket Icon" />
                                    </td>
                                    <td className='py-2'>
                                        <p className='text-sm'>
                                            Registration closes (Nepal Time):<br />
                                            <strong>
                                                {new Date(event.close_registration_at).toLocaleDateString(
                                                    'en-US',
                                                    { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kathmandu' }
                                                )}</strong> <br />
                                        </p>
                                    </td>
                                </tr>
                                {event &&
                                    event.category_details &&
                                    event.category_details.length > 0 &&
                                    event.category_details.some(category => category.is_ticket_limited) ? (
                                    <tr>
                                        <td className='flex  justify-center w-8 '>
                                            <img src={images.ticket} className="w-5" alt="Ticket Icon" />
                                        </td>
                                        <td>
                                            <p className='text-sm'>Limited Tickets</p>
                                        </td>
                                    </tr>
                                ) : null}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (!event.close_registration_forcefully && daysUntilRegistrationOpens > 0 ? (
                <>
                    <div className='border-2 p-8'>
                        <div className="space-y-4">
                            <h5 className='font-bold'>{event.name}</h5>
                            <small>{new Date(event.start_datetime).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', timeZone: 'Asia/Kathmandu' })}</small>

                        </div>

                        <div className='w-full text-center bg-gray-400 p-3 text-white mt-5 uppercase'>
                            <div>Registration<br />Opening in {daysUntilRegistrationOpens} Days </div>
                        </div>
                    </div>
                    <div className='px-8 py-4 flex space-x-4 '>
                        <img src={images.deadline} className='w-4' alt="" />
                        <p className='text-sm'>
                            Registration opens on
                            <br />
                            <strong>
                                {new Date(event.open_registration_from).toLocaleDateString(
                                    'en-US',
                                    { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kathmandu' }
                                )}</strong>
                        </p>
                    </div>
                </>
            ) : (<div className='border-2 p-8 bg-gray-300'>
                <div className="space-y-4">
                    <h5 className='font-bold'>{event.name}</h5>
                    <small>{new Date(event.start_datetime).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', timeZone: 'Asia/Kathmandu' })}</small>
                </div>

                <div className='w-full text-center bg-gray-400 rounded-md p-3 text-white mt-5 uppercase'>
                    <div>Registeration Closed</div>
                </div>
            </div>
            )
            )
            }
        </div >
    );
};

export default EventRegistration;
