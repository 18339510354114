import React, { useEffect, useState } from 'react';
import './About.css';

function About() {
    const [isVisible, setIsVisible] = useState(false);
    const [raceCount, setRaceCount] = useState(0);
    const [intlRaceCount, setIntlRaceCount] = useState(0);
    const [traineeCount, setTraineeCount] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const section = document.getElementById('animated-section');
            if (section) {
                const rect = section.getBoundingClientRect();
                const windowHeight = window.innerHeight || document.documentElement.clientHeight;
                if (rect.top <= windowHeight * 1.1) {
                    setIsVisible(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            startCounting();
        }
    }, [isVisible]);

    const startCounting = () => {
        let raceCounter = 0;
        let intlRaceCounter = 0;
        let traineeCounter = 0;

        const raceInterval = setInterval(() => {
            if (raceCounter <= 25) {
                setRaceCount(raceCounter);
                raceCounter += 1;
            } else {
                clearInterval(raceInterval);
            }
        }, 100);

        const intlRaceInterval = setInterval(() => {
            if (intlRaceCounter <= 10) {
                setIntlRaceCount(intlRaceCounter);
                intlRaceCounter += 1;
            } else {
                clearInterval(intlRaceInterval);
            }
        }, 100);

        const traineeInterval = setInterval(() => {
            if (traineeCounter <= 100) {
                setTraineeCount(traineeCounter);
                traineeCounter += 1;
            } else {
                clearInterval(traineeInterval);
            }
        }, 35);
    };

    return (
        <div className="h-auto flex flex-col p-10 lg:px-20 lg:pt-20 lg:pb-10 bg-[#09090b] text-white font-custom items-center">
            <div className='max-w-[85rem] mx-auto'>
                <div id="animated-section">
                    <div className='flex flex-wrap items-center'>
                        <div className="w-full pb-20 lg:pb-0 lg:w-6/12 xl:w-6/12">
                            <div className="aspect-w-16 aspect-h-9 lg:pr-20 relative">
                                <iframe
                                    src="https://www.youtube.com/embed/-pCk7D-JhAY?controls=0&fs=0&loop=1&color=white&iv_load_policy=3"
                                    width={'100%'}
                                    height={'270'}
                                    className="rounded-md"
                                    title='Himalayan Trail Running Intro'
                                />
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 xl:w-6/12">
                            <div>
                                <h6 className='text-red-600'>ABOUT</h6>
                                <div>
                                    <div className='font-extrabold text-3xl'>We are here for the love of running on high trails.</div><br />
                                    <p className='text-sm'>There is a huge playground above us in the Himalayas and as trail runners, we want to run it every time we are heading there. Walking and flat terrain is too boring for us. And we guess there are a few more people who are on the same page.<br /><br />
                                        We have created these adventures to share our playground with trail-running minded people who want to see experience the epic places of Himalayas in Nepal. Lighter and faster than the regular trekking tours.</p>
                                    <br />
                                    <a href='/about' className='mr-2 uppercase p-2 border-2 text-xs hover:bg-transparent hover:border-red-600  duration-300'>learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-20 lg:mt-36 pb-10 ">
                        <h6 className='text-center text-slate-500'>
                            <h6 className='text-red-600'>SERVICES</h6>
                        </h6>
                        <h2 className="text-3xl font-semibold text-center">
                            What We Do
                        </h2>
                        <p className="mt-2 text-gray-500 text-center text-sm mb-6">
                            We specialize in organizing and managing trail running races while also providing comprehensive training programs for volunteers and runners. With our expertise, we create unforgettable race experiences and empower individuals to contribute effectively to the success of our events.
                        </p>
                        <div className="flex flex-wrap w-full">
                            <div className="w-full flex flex-wrap justify-between ">
                                <div className="flex w-full lg:w-1/3 items-center">
                                    <div className='w-1/4 leading-none text-center align-middle p-2'>
                                        <p className='text-[36px] font-bold leading-none'>
                                            {raceCount}+
                                        </p>
                                        <p className='text-sm leading-none'>Races </p>
                                    </div>
                                    <div className="w-3/4 p-2">
                                        <h3 className="text-xl font-semibold mt-4 align-top">Organize</h3>
                                        <p className="mt-2 text-gray-500 text-sm">We have successfully organized an impressive portfolio of 25 races, establishing ourselves as the leading trail race organizers in Nepal. Our extensive experience ensures the highest level of expertise and professionalism for every event we undertake.</p>
                                    </div>
                                </div>
                                <div className="flex w-full lg:w-1/3  items-center">
                                    <div className='w-1/4 leading-none text-center  align-middle p-2'>
                                        <p className='text-[36px] font-bold leading-none'>
                                            {intlRaceCount}+
                                        </p>
                                        <p className='text-sm  leading-none'>International <br /> Races</p>
                                    </div>
                                    <div className="w-3/4 p-2">
                                        <h3 className="text-xl font-semibold mt-4 align-top">Manage</h3>
                                        <p className="mt-2 text-gray-500 text-sm">We excel in managing international trail races with a proven track record of 10+ successful events. From logistics to registration, safety to timing, our experienced team ensures a seamless and memorable experience for all.</p>
                                    </div>
                                </div>
                                <div className="flex w-full lg:w-1/3 items-center">
                                    <div className='w-1/4 leading-none text-center align-middle p-2'>
                                        <p className='text-[36px] font-bold leading-none'>
                                            {traineeCount}+
                                        </p>
                                        <p className='text-sm leading-none'>Trainees</p>
                                    </div>
                                    <div className="w-3/4 p-2">
                                        <h3 className="text-xl font-semibold mt-4 align-top">Train</h3>
                                        <p className="mt-2 text-gray-500 text-sm">
                                            With pride, we have trained over 100 volunteers and runners in the intricacies of trail running races. Our comprehensive training equips participants with valuable knowledge and skills, ensuring a safe and enjoyable experience for all involved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default About;
