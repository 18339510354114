import React, { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export default function SimpleGallery({ galleryID, images }) {
  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: `#${galleryID}`,
      children: 'a',
      pswpModule: () => import('photoswipe'),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
    };
  }, [galleryID]);

  return (
    <div className="pswp-gallery grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 gap-4" id={galleryID}>
      {images.map((image, index) => (
        <a
          href={image.largeURL}
          data-pswp-width={image.width}
          data-pswp-height={image.height}
          key={`${galleryID}-${index}`}
          target="_blank"
          rel="noreferrer"
        >
          <img className='h-full aspect-square object-cover rounded-sm' src={image.thumbnailURL} alt="" />
        </a>
      ))}
    </div>
  );
}
