import { useEffect, useState } from 'react';
import { get } from '../../api/index';
import getFlagUrlByCountryName from "../../constants/country";
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import './MainDashboard.css'

const MainDashboard = () => {
    const navigate = useNavigate();
    const [ticket, setTicket] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        get(`/sales/tickets/`).then((res) => {
            const sortedTickets = res.results.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setTicket(sortedTickets);
        });
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = ticket.slice(indexOfFirstItem, indexOfLastItem);

    const handleInvoiceView = async (invoiceUUID) => {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        await delay(300);
        navigate(`/invoice/${invoiceUUID}`);
    };

    return (
        <>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Dashboard</h1>
                </div>
            </header>

            {/*<!-- Table Section --> */}
            <div className=" max-w-7xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 pb-10 lg:pb-14">
                    {/*<!-- Card -->*/}
                    <div className="flex flex-col bg-white border shadow-sm rounded-xl  ">
                        <div className="p-4 md:p-5 flex gap-x-4">
                            <div className="flex-shrink-0 flex justify-center items-center w-[46px] h-[46px] bg-gray-100 rounded-md ">
                                <svg className="w-4 h-4 text-gray-600 " xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                </svg>
                            </div>

                            <div className="grow">
                                <div className="flex items-center gap-x-2">
                                    <p className="text-xs uppercase tracking-wide text-gray-500">
                                        Tickets
                                    </p>
                                </div>
                                <div className="mt-1 flex items-center gap-x-2">
                                    <h3 className="text-xl font-medium text-gray-800 ">
                                        {ticket.length}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<!-- End Card -->*/}

                </div>
                {/*<!-- Card --> */}
                <div className="flex flex-col">
                    <div className="-m-1.5 overflow-x-auto">
                        <div className="p-1.5 min-w-full inline-block align-middle">
                            <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden  ">
                                {/*<!-- Header --> */}
                                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                                    <div>
                                        <h2 className="text-xl font-semibold text-gray-800 ">
                                            Tickets
                                        </h2>
                                        <p className="text-sm text-gray-600 ">
                                            Your tickets are listed below
                                        </p>
                                    </div>

                                </div>
                                {/*<!-- End Header --> */}


                                {/*<!-- Table --> */}
                                <table className="min-w-full divide-y divide-gray-200 ">
                                    <thead className="bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="px-4 py-3 text-center">

                                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                    #
                                                </span>

                                            </th>

                                            <th scope="col" className="px-6 py-3 text-left">

                                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                    Event
                                                </span>

                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left">

                                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                    Full Name
                                                </span>

                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left">
                                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                    Bill Amount
                                                </span>
                                            </th>

                                            <th scope="col" className="px-6 py-3 text-left">
                                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                    Created
                                                </span>
                                            </th>

                                            <th scope="col" className="px-2 py-3 text-center">
                                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                    Status
                                                </span>
                                            </th>

                                            <th scope="col" className="px-6 py-3 text-right"></th>
                                        </tr>
                                    </thead>

                                    <tbody className="divide-y divide-gray-200 ">
                                        {currentItems?.map((ticketItem, index) => (
                                            <tr key={index} className="bg-white hover:bg-gray-50"  >
                                                <td className="  whitespace-nowrap text-center">
                                                    <a className="block" >
                                                        <div className="px-4 py-2">
                                                            <span className="font-mono text-sm text-blue-600 ">#{ticket.length - index - indexOfFirstItem}</span>
                                                        </div>
                                                    </a>
                                                </td>
                                                <td className="  whitespace-nowrap">

                                                    <div className="px-6 py-2">
                                                        <span className="text-sm text-gray-600 ">{ticketItem.event_str} - {ticketItem.category_str}</span>
                                                    </div>

                                                </td>
                                                <td className="  whitespace-nowrap">

                                                    <div className="px-6 py-2">
                                                        <span className="text-sm text-gray-600 ">{ticketItem.first_name} {ticketItem.last_name}</span>
                                                    </div>

                                                </td>
                                                <td className="whitespace-nowrap">

                                                    <div className="px-6 py-2">
                                                        <span className="text-sm text-gray-600 ">Rs. {Number(ticketItem.bill_amount).toLocaleString('en-NP')}</span>
                                                    </div>

                                                </td>
                                                <td className="whitespace-nowrap">
                                                    <div className="px-6 py-2">
                                                        <span className="text-sm text-gray-600 ">
                                                            {new Date(ticketItem.created_at).toLocaleString('en-US', {
                                                                month: 'short',
                                                                day: '2-digit',
                                                                year: 'numeric',
                                                                timeZone: 'Asia/Kathmandu'
                                                            })}
                                                        </span>
                                                    </div>
                                                </td>

                                                <td className="whitespace-nowrap">
                                                    <div className="px-6 py-2 text-center">
                                                        {ticketItem.is_paid === true && ticketItem.is_refunded === true ? (
                                                            <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium text-blue-800 bg-blue-100">
                                                                Refunded
                                                            </span>
                                                        ) : (
                                                            ticketItem.is_paid === true && ticketItem.is_cancelled === true ? (
                                                                <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium text-gray-800 bg-gray-100">
                                                                    Cancelled
                                                                </span>
                                                            ) : (
                                                                ticketItem.is_paid === true ? (
                                                                    <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium text-green-800 bg-green-100">
                                                                        <svg className="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                                        </svg>
                                                                        Paid
                                                                    </span>
                                                                ) : (
                                                                    <span className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium text-white bg-red-700">
                                                                        <svg className="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                                        </svg>
                                                                        Unpaid
                                                                    </span>
                                                                )
                                                            )
                                                        )}

                                                    </div>

                                                </td>
                                                <td className="whitespace-nowrap">

                                                    <div className="px-6 py-1.5 space-x-2 text-right">
                                                        {ticketItem.is_paid == false ? (
                                                            <>
                                                                <button
                                                                    type='button'
                                                                    className="py-1 px-2 inline-flex justify-center b items-center gap-2 rounded-md border font-medium bg-amber-400 text-white shadow-sm align-middle hover:bg-amber-300 text-sm"
                                                                    onClick={() => handleInvoiceView(ticketItem.invoice_uuid)} 
                                                                >
                                                                    Pay
                                                                </button>
                                                            </>
                                                        ) : null}

                                                        <button type='button' data-hs-overlay={`#hs-ai-invoice-modal-${ticketItem.uuid}`} className="py-1 px-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 text-sm">
                                                            View
                                                        </button>
                                                    </div>

                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                                {/*<!-- End Table --> */}

                                {/*<!-- Footer --> */}
                                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                                    <div>
                                        <p className="text-sm text-gray-600">
                                            <span className="font-semibold text-gray-800">{ticket.length}</span> results
                                        </p>

                                    </div>

                                    <div>
                                        <div className="inline-flex gap-x-2">
                                            <button
                                                onClick={() => setCurrentPage(currentPage - 1)}
                                                disabled={currentPage === 1}
                                                className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                                            >
                                                Prev
                                            </button>

                                            <button
                                                onClick={() => setCurrentPage(currentPage + 1)}
                                                disabled={indexOfLastItem >= ticket.length}
                                                className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                {/*<!-- End Footer --> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- End Card --> */}
            </div>
            {/*<!-- End Table Section --> */}

            {/*<!-- Modal --> */}
            {currentItems?.map((ticketItem, index) => (
                <div key={index} id={`hs-ai-invoice-modal-${ticketItem.uuid}`} className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto">
                    <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0  ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
                        <div className="relative flex flex-col bg-white shadow-lg rounded-xl ">
                            <div className={`ribbon-2 uppercase pr-8 ${ticketItem.is_paid ? "bg-green-500" : "bg-red-700 text-white"}`}>{ticketItem.is_paid ? "Paid" : "Unpaid"}</div>

                            {/*<!-- Close Button --> */}
                            <div className="absolute top-2 right-2">
                                <button type="button" className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-900 transition-all text-sm " data-hs-overlay={`#hs-ai-invoice-modal-${ticketItem.uuid}`} data-hs-remove-element={`#hs-ai-invoice-modal-${ticketItem.uuid}`}>
                                    <span className="sr-only">Close</span>
                                    <svg className="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor" />
                                    </svg>
                                </button>
                            </div>
                            {/*<!-- End Close Button --> */}



                            {/*<!-- Body --> */}
                            <div className="p-4 sm:p-7  overflow-y-auto">

                                <div className="mt-5 sm:mt-10">
                                    <span className="block text-xs uppercase text-gray-500">Ticket for</span>
                                    <h4 className=" font-semibold uppercase text-gray-800 ">{ticketItem.event_str}</h4>
                                    <span className="block text-xs uppercase text-gray-500">
                                        Created on:&nbsp;
                                        {new Date(ticketItem.created_at).toLocaleString('en-US', {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: "2-digit",
                                            minute: '2-digit',
                                            timeZone: 'Asia/Kathmandu'
                                        })}
                                         &nbsp;(Nepal Time)
                                    </span>
                                </div>
                                {/*<!-- Grid --> */}
                                <div className="mt-2 sm:mt-5 grid grid-cols-2 sm:grid-cols-3 gap-5">
                                    <div className='col-span-3'>
                                        <span className="block text-xs uppercase text-gray-500">Personal Details</span>
                                        <hr />
                                    </div>

                                    <div className='col-span-3 sm:col-span-1'>
                                        <span className="block text-xs uppercase text-gray-500">Full Name:</span>
                                        <span className="block text-sm font-medium text-gray-800 ">{ticketItem.first_name} {ticketItem.last_name}</span>
                                    </div>
                                    <div>
                                        <span className="block text-xs uppercase text-gray-500">Gender:</span>
                                        <span className="block text-sm font-medium text-gray-800 capitalize">{ticketItem.gender}</span>
                                    </div>

                                    {/*<!-- End Col --> */}

                                    <div>
                                        <span className="block text-xs uppercase text-gray-500">Date of Birth</span>
                                        <div className="block text-sm break-normal font-medium text-gray-800">
                                            {new Date(ticketItem.dob).toLocaleString('en-US', {
                                                month: 'short',
                                                day: '2-digit',
                                                year: 'numeric'
                                            })}
                                            <br />
                                        </div>

                                    </div>
                                    {/*<!-- End Col --> */}

                                    <div>
                                        <span className="block text-xs uppercase text-gray-500">Country:</span>
                                        <div className="flex items-center gap-x-2">
                                            <span className="block text-sm font-medium text-gray-800">{ticketItem.country}</span>
                                            {/* <img
                                                src={getFlagUrlByCountryName(ticketItem.country)}
                                                alt={`${ticketItem.country} Flag`}
                                                className="w-4"
                                            /> */}

                                        </div>
                                    </div>
                                    <div className='col-span-3 sm:col-span-1'>
                                        <span className="block text-xs uppercase text-gray-500">Address:</span>
                                        <span className="block text-sm font-medium text-gray-800 capitalize">{ticketItem.address}</span>
                                    </div>
                                    <div className='col-span-3 sm:col-span-1'>
                                        <span className="block text-xs uppercase text-gray-500">Email:</span>
                                        <span className="block text-sm font-medium text-gray-800 break-all">{ticketItem.email}</span>
                                    </div>
                                    <div>
                                        <span className="block text-xs uppercase text-gray-500">Phone Number:</span>
                                        <span className="block text-sm font-medium text-gray-800 break-all">{ticketItem.phone_number}</span>
                                    </div>

                                    <div>
                                        <span className="block text-xs uppercase text-gray-500">Blood Group:</span>
                                        <span className="block text-sm font-medium text-gray-800 break-all">{ticketItem.blood_group}</span>
                                    </div>
                                    <div className='col-span-3 sm:col-span-3'>
                                        <span className="block text-xs uppercase text-gray-500">Emergency Contact:</span>
                                        <span className="block text-sm font-medium text-gray-800 break-all">{ticketItem.emergency_name}({ticketItem.relation}) - {ticketItem.emergency_contact}</span>
                                    </div>
                                    <div className='col-span-3 sm:col-span-3'>
                                        <span className="block text-xs uppercase text-gray-500">Size Preference:</span>
                                        <span className="block text-sm font-medium text-gray-800 break-all">{ticketItem.size_preference}</span>
                                    </div>
                                </div>

                                <div className="mt-4 sm:mt-5 grid grid-cols-3 sm:grid-cols-3 gap-5">
                                    <div className='col-span-3'>
                                        <span className="block text-xs uppercase text-gray-500">Ticket Details</span>
                                        <hr />
                                    </div>
                                    <div className='col-span-1 sm:col-span-1'>
                                        <span className="block text-xs uppercase text-gray-500">Category:</span>
                                        <span className="block text-sm font-medium text-gray-800 ">{ticketItem.category_str}</span>
                                    </div>
                                    <div className='col-span-3 sm:col-span-2'>
                                        <span className="block text-xs uppercase text-gray-500">Addons:</span>

                                        {Object.entries(ticketItem.addon_info)
                                            .filter(([name, quantity]) => quantity !== 0)
                                            .map(([name, quantity], index) => (
                                                <div key={index} className=" text-sm flex flex-row justify-between pr-4 font-medium text-gray-800 capitalize">
                                                    <span className="mr-2">{name}</span>
                                                    <span>x {quantity}</span>
                                                </div>
                                            ))}
                                    </div>
                                    {ticketItem.user_note != 0 &&
                                        <div className='col-span-1 sm:col-span-3'>
                                            <span className="block text-xs uppercase text-gray-500">User Note:</span>
                                            <span className="block text-sm font-medium text-gray-800 ">{ticketItem.user_note}</span>

                                        </div>
                                    }
                                </div>



                                {/*<!-- Button --> */}
                                <div className="mt-5 flex justify-center gap-x-2" >
                                    <button type='button' data-hs-overlay={`#hs-ai-invoice-modal-${ticketItem.uuid}`} data-hs-remove-element={`#hs-ai-invoice-modal-${ticketItem.uuid}`} className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm" onClick={() => handleInvoiceView(ticketItem.invoice_uuid)} >
                                        <RemoveRedEyeIcon />
                                        View Invoice
                                    </button>
                                    {/* <a className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm ">
                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                                            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                        </svg>
                                        Print
                                    </a> */}
                                </div>
                                {/*<!-- End Buttons --> */}

                                <div className="mt-5 sm:mt-5">
                                    <p className="text-sm text-gray-500">If you have any questions, please contact us at <a className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium" href='mailto:himalayantrailrunning@gmail.com'>himalayantrailrunning@gmail.com</a> or call at  <a className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium" href="tel:+9779846031080">+977 9846-031-080</a></p>
                                </div>
                            </div>
                            {/*<!-- End Body --> */}
                        </div>
                    </div>
                </div>
            ))}
            {/* <!--End Modal--> */}
        </>
    )
}

export default MainDashboard;