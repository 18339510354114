import React from 'react';

const EventPrize = ({ event }) => {
    if (!event || !event.category_details) {
        return null; // Add a check for event and category_details
    }

    // Filter categories that have at least one prize with a prize.id
    const categoriesWithPrizes = event.category_details.filter(
        (category) => category.prizes_details.some((prize) => prize.id)
    );

    // Check if there are any categories with prizes to render
    const hasPrizes = categoriesWithPrizes.length > 0;

    return (
        <>
            {hasPrizes && (
                <section id='prize' className='scroll-mt-32 pb-20'>
                    <h5 className='font-extrabold tracking-wide pb-4'>Prize</h5>
                    {event.category_details.map((item, index) => (
                        <div key={index} className='[&>ul]:text-sm [&>p]:text-sm [&>p]:leading-relaxed [&>ul]:leading-relaxed [&>ul]:list-disc [&>ul]:indent-2 [&>p]:pb-4' dangerouslySetInnerHTML={{ __html: item.winning_conditions }}></div>
                    ))}

                    <div className='grid grid-cols-1 sm:grid-cols-2 justify-between -m-4'>
                        {categoriesWithPrizes.map((category) => (
                            <div key={category.name} className="overflow-x-auto grow rounded-sm border-2 p-4 m-4">
                                <h5 className="uppercase font-bold leading-loose">{category.name}</h5>
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="pr-3 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                                                Rank
                                            </th>
                                            <th scope="col" className="pr-3 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                                                Male 
                                            </th>
                                            <th scope="col" className="pr-3 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                                                Female
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {category.prizes_details.reduce((rows, prize) => {
                                            // Find an existing row for the same rank or create a new one
                                            let row = rows.find((r) => r.rank === prize.rank);
                                            if (!row) {
                                                row = { rank: prize.rank, malePrize: null, femalePrize: null };
                                                rows.push(row);
                                            }

                                            // Update the prize based on gender
                                            if (prize.gender === 'male') {
                                                row.malePrize = `Rs. ${Number(prize.prize).toLocaleString('en-NP')}`;
                                            } else if (prize.gender === 'female') {
                                                row.femalePrize = `Rs. ${Number(prize.prize).toLocaleString('en-NP')}`;
                                            }

                                            return rows;
                                        }, []).map((row) => (
                                            <tr key={row.rank}>
                                                <td className="pr-3 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{row.rank}</td>
                                                <td className="pr-3 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
                                                    {row.malePrize || '-'}
                                                </td>
                                                <td className="pr-3 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
                                                    {row.femalePrize || '-'}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ))}

                    </div>
                </section>
            )}
        </>
    );
};

export default EventPrize;
