import React, { useEffect, useState } from 'react';
import './event.css';
import { useParams } from "react-router-dom";
import { get } from '../api/index';
import {
  EventDetailsNav, EventOverview, EventRoute, EventSchedule,
  EventWhatToBring, EventHeader, EventPrize,
  EventFAQ, EventGallery, EventRegistration, EventPricing
} from '../components/EventPage';
import { images } from '../constants';

const EventDetail = () => {
  const { slug } = useParams();
  const [event, setEvent] = useState({});
  const [gallery, setGallery] = useState([]);
  const [accordionActive, setAccordionActive] = useState(false);

  useEffect(() => {
    get(`/events/active/${slug}/`).then((res) => {
      setEvent(res);
    });

    get(`/events/active/${slug}/medias/`).then((res) => {
      setGallery(res);
    });
  }, [slug]);

  const [daysUntilRegistrationOpens, setDaysUntilRegistrationOpens] = useState(null);

  useEffect(() => {
    if (event && event.open_registration_from) {
      const now = new Date().getTime();
      const openRegistrationTime = new Date(event.open_registration_from).getTime();

      if (now < openRegistrationTime) {
        const timeUntilRegistrationOpens = openRegistrationTime - now;
        const daysUntilOpens = Math.ceil(timeUntilRegistrationOpens / (1000 * 3600 * 24));
        setDaysUntilRegistrationOpens(daysUntilOpens);
      }
    }
  }, [event]);

  if (!event || !event.category_details || !Array.isArray(event.category_details)) {
    return <div className="progress-loader">
      <div className="progress"></div>
    </div>;
  }

  // Extracting prices for each category
  const nepaliPrices = event.category_details.map((category) => category.nepali_price);
  const saarcPrices = event.category_details.map((category) => category.saarc_price);
  const internationalPrices = event.category_details.map((category) => category.international_price);

  // Finding the maximum and minimum values for each category
  const maxNepaliPrice = Math.max(...nepaliPrices);
  const minNepaliPrice = Math.min(...nepaliPrices);

  const maxSaarcPrice = Math.max(...saarcPrices);
  const minSaarcPrice = Math.min(...saarcPrices);

  const maxInternationalPrice = Math.max(...internationalPrices);
  const minInternationalPrice = Math.min(...internationalPrices);

  // Function to check if the prices are the same
  const isSamePrice = (max, min) => max === min;

  const registrationLink = `/events/${event.slug}/register`;


  return (
    <>
      <div className="w-full overflow-clip font-custom">
        <EventHeader event={event} />
        <EventDetailsNav event={event} gallery={gallery} />
        <div>
          <div className="h-auto p-10 mt-4 lg:px-20">
            <div className='max-w-[85rem] mx-auto'>
              <div className="flex flex-wrap ">
                <div className="w-full md:w-9/12 md:pr-20">
                  <EventOverview event={event} />
                  <EventGallery event={event} gallery={gallery} />
                  <EventRoute event={event} />
                  <EventSchedule event={event} />
                  <EventPricing event={event} />
                  <EventPrize event={event} />
                  <EventWhatToBring event={event} />
                  <EventFAQ event={event} />
                </div>
                <div className="hidden md:block  md:w-3/12">
                  <EventRegistration event={event} />
                </div>
              </div>
            </div>

          </div>
          <footer className="block md:hidden z-50 sticky bottom-0 mt-auto py-3 bg-gray-800 visible md:ini">
            <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 justify-between items-center text-white">
              {event.is_registration_open ? (
                <>
                  <div className="hs-accordion-group">
                    <div className="hs-accordion" id="hs-basic-with-arrow-heading-one">
                      <button
                        className={`hs-accordion-toggle ${accordionActive ? 'hs-accordion-active:text-red-600 group' : ''
                          } py-3 inline-flex items-center gap-x-3 w-full font-semibold text-left hs-accordion-active:text-white text-gray-200 transition hover:text-gray-500`}
                        aria-controls="hs-basic-with-arrow-collapse-one"
                        onClick={() => setAccordionActive(!accordionActive)} // Toggle accordion state
                      ><svg className="hs-accordion-active:hidden hs-accordion-active:text-white hs-accordion-active:group-hover:text-red-600 block w-3 h-3 text-gray-400 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                        <svg className="hs-accordion-active:block hs-accordion-active:text-red-600 hs-accordion-active:group-hover:text-red-600 hidden w-3 h-3 text-gray-400 group-hover:text-gray-500 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                        Pricing & Deadline
                      </button>
                      <div
                        id="hs-basic-with-arrow-collapse-one"
                        className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${accordionActive ? 'block' : 'hidden'
                          }`}
                        aria-labelledby="hs-basic-with-arrow-heading-one"
                      ><div className="flex flex-col py-4">
                          <div>
                            <h5 className='font-bold'>{event.name}</h5>
                            <small>{new Date(event.start_datetime).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', timeZone: 'Asia/Kathmandu' })}</small>
                          </div>
                          <div>
                            <p className='mt-4 mb-0 uppercase text-xs '>Starting Price</p>
                            <div className='flex flex-wrap justify-between'>
                              {maxNepaliPrice > 0 && minNepaliPrice > 0 && (
                                <div>
                                  <small><strong>Nepali</strong></small>
                                  <p className='text-sm'>Rs. {Number(minNepaliPrice).toLocaleString('en-NP')}</p>
                                </div>
                              )}
                              {maxSaarcPrice > 0 && minSaarcPrice > 0 && (
                                <div>
                                  <small><strong>SAARC</strong></small>
                                  <p className='text-sm'>Rs. {Number(minSaarcPrice).toLocaleString('en-NP')}</p>
                                </div>
                              )}
                              {maxInternationalPrice > 0 && minInternationalPrice > 0 && (
                                <div>
                                  <small><strong>International</strong></small>
                                  <p className='text-sm'>Rs. {Number(minInternationalPrice).toLocaleString('en-NP')}</p>
                                </div>
                              )}
                            </div>
                            <a href="#pricing" className='text-xs underline'>Detailed Pricing</a>
                          </div>
                          <div className='py-2'>
                            <table  >
                              <tbody>
                                <tr >
                                  <td className='flex justify-start w-8 py-2'>
                                    <img src={images.deadline} className="w-4" alt="Ticket Icon" />
                                  </td>
                                  <td className='py-2'>
                                    <small>
                                      Registration closes{' '}
                                      {new Date(event.close_registration_at).toLocaleDateString(
                                        'en-US',
                                        { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kathmandu' }
                                      )} (Nepal Time)
                                    </small>
                                  </td>
                                </tr>
                                {event &&
                                  event.category_details &&
                                  event.category_details.length > 0 &&
                                  event.category_details[0].is_ticket_limited ? (
                                  <tr>
                                    <td className='flex  justify-start w-8'>
                                      <img src={images.ticket} className="w-5" alt="Ticket Icon" />
                                    </td>
                                    <td>
                                      <small>Limited Tickets</small>
                                    </td>
                                  </tr>
                                ) : null}
                              </tbody>
                            </table>
                          </div>

                        </div>
                        <div className='flex flex-col space-y-2'>

                        </div>
                      </div>
                    </div>
                  </div>
                  <a href={registrationLink} >
                    <div className='w-full text-center rounded-md bg-red-600 p-3 text-white mt-2 uppercase'>
                      Register
                    </div>
                  </a>
                </>

              ) : (
                !event.close_registration_forcefully && daysUntilRegistrationOpens > 0 ? (
                  <div className='px-8 py-2 flex space-x-4'>
                    <svg id="Layer_2" data-name="Layer 2" className='w-4 fill-red-600' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.45 41.36">
                      <path d="m0,23.77c0-8.05,5.45-15.11,13.14-17.24,5.28-1.46,10.11-.38,14.62,2.52.5.32.78.39,1.18-.15.6-.8,1.31-1.52,1.95-2.28.24-.29.43-.37.75-.09.77.68,1.56,1.33,2.35,1.97.26.21.28.34.04.62-.8.9-1.55,1.85-2.34,2.76-.3.34-.29.58,0,.94,2.81,3.59,4.18,7.73,3.67,12.21-.83,7.23-4.53,12.56-11.52,15.14-6.81,2.51-12.94,1.02-18.25-3.77C2.45,33.57.75,29.9.13,25.73c-.11-.71-.13-1.42-.12-1.96Zm19.63-7.67c0-1.21-.02-2.41,0-3.62.01-.5-.17-.64-.64-.62-.82.03-1.64.04-2.45,0-.58-.03-.78.14-.77.75.03,2.27,0,4.54.02,6.8,0,.54-.16.93-.58,1.26-1.36,1.06-1.59,2.71-1.11,4.22.51,1.6,1.91,2.51,3.67,2.52,1.64,0,2.98-.94,3.55-2.52.54-1.47.05-3.31-1.19-4.27-.39-.3-.52-.62-.51-1.08.02-1.14,0-2.29,0-3.43Z" />
                      <path d="m17.69,4.02c-1.74,0-3.47-.02-5.21.01-.53,0-.73-.15-.7-.7.04-.92.02-1.84,0-2.76C11.77.15,11.92,0,12.35,0c3.58.01,7.15.01,10.73,0,.42,0,.59.14.58.57-.02.94-.03,1.88,0,2.82.01.48-.15.64-.64.64-1.78-.02-3.56,0-5.33,0Z" />
                    </svg>
                    <p className='text-sm'>
                      Registration opens on
                      <br />
                      <strong>
                        {new Date(event.open_registration_from).toLocaleDateString(
                          'en-US',
                          { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kathmandu' }
                        )}</strong> ( In {daysUntilRegistrationOpens} Days)
                    </p>
                  </div>
                ) : (
                  <div className='w-full text-center bg-gray-400 p-3 text-white  uppercase'>
                    <div>Registeration Closed</div>
                  </div>
                )
              )
              }
            </div>
          </footer>
        </div>

      </div>
    </>
  );
};
export default EventDetail;
