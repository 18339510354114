import React from 'react';
import "./EventWhatToBring.css";


const EventWhatToBring = ({ event }) => {
  // Check if there are any required or recommended things to bring
  const hasRequiredToBring = event.things_required_to_bring && event.things_required_to_bring.trim() !== "";
  const hasRecommendedToBring = event.things_recommended_to_bring && event.things_recommended_to_bring.trim() !== "";

  // Check if there are any things to bring to render the section
  const hasThingsToBring = hasRequiredToBring || hasRecommendedToBring;

  return (
    <>
      {hasThingsToBring && (
        <section id='whattobring' className='scroll-mt-32 pb-10'>
          <div className="w-full">
            <h5 className='font-extrabold tracking-wide pb-4'>
              What to bring?
            </h5>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-16 ">
              {hasRequiredToBring && (
                <div>
                  <h2 className="text-lg font-semibold mb-2">Required</h2>
                  <div className='[&>ul]:text-sm [&>p]:text-sm [&>p]:leading-relaxed [&>ul]:leading-relaxed [&>ul]:list-disc [&>ul]:indent-2 pl-4' dangerouslySetInnerHTML={{ __html: event.things_required_to_bring }} />
                </div>
              )}

              {hasRecommendedToBring && (
                <div>
                  <h2 className="text-lg font-semibold mb-2">Recommended</h2>
                  <div className='[&>ul]:text-sm [&>p]:text-sm [&>p]:leading-relaxed [&>ul]:leading-relaxed [&>ul]:list-disc [&>ul]:indent-2 pl-4' dangerouslySetInnerHTML={{ __html: event.things_recommended_to_bring}} />
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default EventWhatToBring;

