import React, { useCallback, useState, useEffect } from 'react';
import { signInAsync } from '../../authRedux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

const SignIn = ({ handleForgotPasswordClick }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [loginError, setLoginError] = useState(false);

    const [values, setValues] = useState({
        email: '',
        password: ''
    });

    const [rememberMe, setRememberMe] = useState(false);

    useEffect(() => {
        const rememberMeCookie = Cookies.get('rememberMe');
        setRememberMe(rememberMeCookie === 'true');
    }, []);

    const handleChange = useCallback(
        (event) => {
            setValues((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value
            }));
        },
        []
    );

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await dispatch(signInAsync(values.email, values.password));
            const from = location.state?.from || '/dashboard';
            navigate(from);
        } catch (error) {
            event.preventDefault();
            setLoginError(true);
        }
    };

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
    };

    return (
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <div className="grid gap-y-4">
                <div>
                    <label className="block text-sm mb-2 ">Email address</label>
                    <div className="relative">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-red-600 focus:ring-red-600"
                            required
                            aria-describedby="email-error"
                        />
                    </div>
                </div>

                <div>
                    <div className="flex justify-between items-center">
                        <label className="block text-sm mb-2 ">Password</label>
                        <a
                            className="text-sm text-gray-800 decoration-2 hover:underline font-medium cursor-pointer"
                            onClick={handleForgotPasswordClick}
                        >
                            Forgot password?
                        </a>
                    </div>
                    <div className="relative">
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-red-600 focus:ring-red-600"
                            required
                            aria-describedby="login-error"
                        />
                    </div>
                    <p
                        className={`${loginError ? 'text-xs text-red-600 mt-2' : 'hidden'
                            }`}
                        id="login-error"
                    >
                        Wrong username or password. Try again or click Forgot password to reset it.
                    </p>
                </div>

                <div className="flex items-center">
                    <div className="flex">
                        <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="shrink-0 mt-0.5 border-gray-200 rounded text-red-600 focus:ring-red-600 cursor-pointer"
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                        />

                    </div>
                    <div className="ml-3">
                        <label className="text-sm ">Remember me</label>
                    </div>
                </div>

                <button
                    type="submit"
                    className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-600 text-white hover:bg-red-700 ease-in duration-200 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 transition-all text-sm "
                >
                    Sign in
                </button>
            </div>
        </form>
    );
};

export default SignIn;
