import { React, useState, useEffect } from 'react'
import { get } from '../../../api/index';
import './Trusted.css'

const Trusted = () => {
    const [logos, setLogos] = useState([]);
    useEffect(() => {
        get('/content/logos/')
            .then((res) => {
                if (res) {
                    setLogos(res['results']);
                }
            })
            .catch((error) => {
                console.error('Error occurred while fetching data:', error);
            });
    }, []);

    if (logos.length === 0) {
        return null; // or you can return a loading spinner or a message
    }

    return (
        <div className='bg-[#09090b] p-10 pb-10 hidden md:block'>
            <div className='max-w-[85rem] mx-auto '>
                <h6 className='text-center pb-4 text-slate-500'>
                    <h6 className='text-gray-600 opacity-50 font-semibold uppercase'>Teamed up with</h6>
                </h6>
                <div className="flex items-center h-10 mt-10 justify-center">

                    {/* 1. */}
                    <div className="w-[300%] h-20 relative">
                        {/* 2. */}
                        <div className="w-[300%] snap-mandatory flex items-center h-20 justify-around absolute left-0 gap-x-16 sm:gap-x-16  md:gap-x-10 lg:gap-x-32 animate">
                            {/* 3 */}
                            {logos.map((trusted, index) => {
                                return (
                                    <a key={index} href={trusted.link} target="_blank" rel="noopener noreferrer">
                                        <div className="flex flex-nowrap snap-center justify-center items-start w-[40] grayscale hover:grayscale-0 duration-300">
                                            <img src={trusted.logo} alt={trusted.link} />
                                        </div>
                                    </a>
                                );
                            })}

                            {logos.map((trusted, index) => {
                                return (
                                    <a key={index} href={trusted.link} target="_blank" rel="noopener noreferrer">
                                        <div className="flex snap-center justify-center items-start w-[40] grayscale hover:grayscale-0 duration-300">
                                            <img src={trusted.logo} alt={trusted.link} />
                                        </div>
                                    </a>
                                );
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trusted

