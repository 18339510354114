import { Link } from 'react-router-dom';

const Events = ({ events }) => {

    return (
        <>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Events</h1>
                </div>
            </header>
            <main>
                <div className="max-w-7xl w-full px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    {/*<!-- Card --> */}
                    <div className="flex flex-col">
                        <div className="-m-1.5 overflow-x-auto">
                            <div className="p-1.5 min-w-full inline-block align-middle">
                                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden  ">
                                    {/*<!-- Header --> */}
                                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                                        <div>
                                            <h2 className="text-xl font-semibold text-gray-800 ">
                                                Events
                                            </h2>
                                            <p className="text-sm text-gray-600 ">
                                                Here are the list of events
                                            </p>
                                        </div>

                                    </div>
                                    {/*<!-- End Header --> */}


                                    {/*<!-- Table --> */}
                                    <table className="min-w-full divide-y divide-gray-200 ">
                                        <thead className="bg-gray-50 ">
                                            <tr>
                                                <th scope="col" className="px-4 py-3 text-center">

                                                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                        #
                                                    </span>
                                                </th>

                                                <th scope="col" className="px-6 py-3 text-left">

                                                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                        Event
                                                    </span>

                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left">

                                                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                        Distances
                                                    </span>

                                                </th>

                                                <th scope="col" className="px-6 py-3 text-left">
                                                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                        Location
                                                    </span>
                                                </th>

                                                <th scope="col" className="px-6 py-3 text-left">
                                                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                        Date
                                                    </span>
                                                </th>

                                                <th scope="col" className="px-6 py-3 text-left">
                                                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                        Registration Deadline
                                                    </span>
                                                </th>

                                                <th scope="col" className="px-6 py-3 text-right"></th>
                                            </tr>
                                        </thead>

                                        <tbody className="divide-y divide-gray-200 ">
                                            {events?.map((event, index) => (
                                                <tr key={index} className="bg-white hover:bg-gray-50"  >
                                                    <td className="  whitespace-nowrap text-center">
                                                        <a className="block" >
                                                            <div className="px-4 py-2">
                                                                <span className="font-mono text-sm text-blue-600 ">#{index + 1}</span>
                                                            </div>
                                                        </a>
                                                    </td>
                                                    <td className="  whitespace-nowrap">
                                                        <div className="px-6 py-2">
                                                            <span className="text-sm text-gray-600 ">{event.name}</span>
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-nowrap">
                                                        <div className="px-6 py-2">
                                                            <span className="text-sm text-gray-600 ">
                                                                {event?.category_details?.reduce((acc, category, index) => {
                                                                    if (index === 0) {
                                                                        return category.name;
                                                                    } else if (index === event.category_details?.length - 1) {
                                                                        return `${acc} & ${category.name}`;
                                                                    } else {
                                                                        return `${acc}, ${category.name}`;
                                                                    }
                                                                }, '')}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-nowrap">

                                                        <div className="px-6 py-2">
                                                            <span className="text-sm text-gray-600 ">{event.general_area}</span>
                                                        </div>

                                                    </td>
                                                    <td className="whitespace-nowrap">
                                                        <div className="px-6 py-2">
                                                            <span className="text-sm text-gray-600 ">
                                                                {new Date(event.start_datetime).toLocaleString('en-US', {
                                                                    month: 'short',
                                                                    day: '2-digit',
                                                                    year: 'numeric',
                                                                    timeZone: 'Asia/Kathmandu'
                                                                })}
                                                            </span>
                                                        </div>
                                                    </td>

                                                    <td className="whitespace-nowrap">
                                                        <div className="px-6 py-2 text-left">
                                                            <span className="text-sm text-gray-600 ">
                                                                {new Date(event.close_registration_at
                                                                ).toLocaleString('en-US', {
                                                                    month: 'short',
                                                                    day: '2-digit',
                                                                    year: 'numeric',
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                    timeZone: 'Asia/Kathmandu'
                                                                })}
                                                            </span>
                                                        </div>

                                                    </td>
                                                    <td className="whitespace-nowrap">

                                                        <div className="px-6 py-1.5 text-center flex space-x-2">
                                                            <button className="py-1 px-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 text-sm    ">
                                                                <Link to={`/events/${event.slug}`}>View</Link>
                                                            </button>
                                                            {event.is_registration_open &&
                                                                <button className="py-1 px-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-red-600 text-white shadow-sm align-middle hover:bg-red-700 text-sm ease-in duration-200">
                                                                    <Link to={`/events/${event.slug}/register`}>Register</Link>
                                                                </button>
                                                            }
                                                        </div>

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {/*<!-- End Table --> */}

                                    {/*<!-- Footer --> */}
                                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                                        <div>
                                            <p className="text-sm text-gray-600">
                                                <span className="font-semibold text-gray-800">{events.length}</span> results
                                            </p>
                                        </div>

                                        <div>
                                            <div className="inline-flex gap-x-2">
                                                <button

                                                    className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                                                >
                                                    Prev
                                                </button>

                                                <button

                                                    className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                    {/*<!-- End Footer --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<!-- End Card --> */}
                    {/*<!-- End Table Section --> */}
                </div>
            </main>
        </>
    )
}

export default Events;