import React from 'react';

const EventFAQ = ({ event }) => {
    // Check if there are any FAQ details to render
    const hasFAQDetails = event.faq_details && event.faq_details.length > 0;

    return (
        <>
            {hasFAQDetails && (
                <section id='FAQ' className='scroll-mt-32'>
                    <h5 className='uppercase font-bold leading-loose'>FAQ</h5>
                    <div>
                        <div className="hs-accordion-group divide-gray-200 -m-4">
                            {event.faq_details?.map((item, index) => (
                                <div key={index} className={`hs-accordion py-3 ${index === 0 ? 'active' : ''} basis-1/2 pr-8 border-2 p-4 m-4`} id={`hs-basic-with-title-and-arrow-stretched-heading-${index}`}>
                                    <button className=" hs-accordion-toggle group inline-flex items-center justify-between gap-x-3 w-full text-sm leading-relaxed font-semibold text-left text-gray-800 transition hover:text-gray-500ay-400" aria-controls={`hs-basic-with-title-and-arrow-stretched-collapse-${index}`}>
                                        {item.question}
                                        <svg className="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                        <svg className="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </button>
                                    <div id={`hs-basic-with-title-and-arrow-stretched-collapse-${index}`} className={` hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${index === 0 ? 'block' : 'hidden'}`} aria-labelledby={`hs-basic-with-title-and-arrow-stretched-heading-${index}`}>
                                        <p className="text-gray-600 text-sm leading-relaxed py-2">
                                            {item.answer}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </section>
            )}
        </>
    );
};

export default EventFAQ;
