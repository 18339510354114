import React from 'react';

function Category({ formData, handleChange, priceApplied, event }) {
  const handleRadioChange = (value) => {
    handleChange({
      target: {
        name: 'category',
        value,
      },
    });
  };

  return (
    <div className="h-auto">
      <div className='max-w-[85rem] mx-auto'>
        <h1 className='text-3xl font-bold mb-4'>Distance, Add-ons and Checkout</h1>
        <div className='pb-4'>
          <h6 className='pb-2 font-bold'>Distance</h6>
          <form className="grid grid-cols-3 gap-2 w-full max-w-screen-sm">
            {event.category_details?.map((value) => (
              <div key={value.uuid}>
                <input
                  type="radio"
                  id={value.uuid}
                  className='hidden'
                  name="category"
                  checked={formData.category === value.uuid}
                  onChange={() => handleRadioChange(value.uuid)}
                />
                <label
                  className={`flex flex-col p-4 border-2 rounded-md border-gray-400 cursor-pointer ${formData.category === value.uuid ? 'border-red-600 bg-red-50' : ''}`}
                  for={value.uuid}>
                  <p className='font-bold text-xl'>{value.name}</p>
                  <div>
                    <p className='text-xs text-gray-500'>{priceApplied === 'SAARC' ? 'SAARC' : priceApplied === 'Nepali' ? 'National' : 'International'}
                    </p>
                    <h6 className='font-semibold'>
                      {
                        priceApplied === 'SAARC'
                          ? `Rs. ${value.saarc_price}`
                          : priceApplied === 'Nepali'
                            ? `Rs. ${value.nepali_price}`
                            : `Rs. ${value.international_price}`
                      }
                    </h6>
                  </div>
                </label>
              </div>
            ))}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Category;
