import react, { useEffect, useState } from 'react';
import { get } from '../api';


const About = () => {
    const [team, setTeam] = useState([]);

    useEffect(() => {
        get('/content/team-members/')
            .then((res) => {
                if (res) {
                    setTeam(res['results']);
                }
            })
            .catch((error) => {
                console.error('Error occurred while fetching data:', error);
            });
    }, []);

    function formatWebsite(website) {
        // Check if the URL starts with "http://" or "https://"
        if (website.startsWith("http://")) {
            return website.slice(7); // Remove the first 7 characters (http://)
        } else if (website.startsWith("https://")) {
            return website.slice(8); // Remove the first 8 characters (https://)
        } else {
            return website; // If no protocol is present, return the original URL
        }
    }

    return (
        <>
            <div className="pt-20 font-custom">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-3xl md:mx-0">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About</h2>
                        <p className="mt-2 text-sm  text-gray-600">
                            There is a huge playground above us in the Himalayas and as trail runners we want to run it every time we are heading there. Walking and flat terrain is too boring for us. And we guess there are a few more people who are on the same page.
                            <br /> <br />
                            We have created these adventures to share our playground with trail-running minded people who want to see experience the epic places of Himalayas in Nepal. Lighter and faster than the regular trekking tours.
                            <br /> <br />
                            It might give you some challenging moments, but you are going to meet wonderful people and will have a great time in the Himalayan mountains.
                        </p>
                    </div>
                    <div className="mx-auto max-w-3xl md:mx-0 mt-8">
                        <h4 className="font-bold tracking-tight text-gray-900 pb-4">Team Members</h4>
                        <div className='flex flex-col gap-8'>
                            {team.slice().reverse().map((teamMember, index) => (
                                <div key={index} className='flex flex-row gap-8'>
                                    <div className='w-1/4'>
                                        <img className='object-cover object-center rounded-lg' src={teamMember.photo} alt={teamMember.photo} />
                                    </div>
                                    <div className='w-3/4 flex flex-col'>
                                        <div>
                                            <h5 className='font-semibold'>{teamMember.name}</h5>
                                            <p className='text-xs uppercase pb-2 font-medium text-red-600'>{teamMember.designation}</p>
                                            <p className='text-sm text-gray-600'>{teamMember.description}</p>
                                        </div>
                                        <div className='py-4 text-sm'>
                                            <a href={teamMember.website} className='text-red-600 hover:text-red-400' target='_blank'>{formatWebsite(teamMember.website)}</a>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;