import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import ScrollToTop from '../ScrollToTop';
import Modal from '@mui/material/Modal';
import CardContent from '@mui/material/CardContent';
import { MediaAPI } from '../../api/_constant';
import './AddonForm.css';
import { TextField } from '@mui/material';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function AddonForm({ previousStep, formData, handleChange, priceApplied, event, addons, setAddons }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedAddon, setSelectedAddon] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Reset the currentIndex when the modal is opened
    if (open) {
      setCurrentIndex(0);
    }
  }, [open]);


  const handleOpenModal = (addon) => {
    setSelectedAddon(addon);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedAddon(null);
    setOpen(false);
  };

  const handleAddClick = (addon) => {
    const updatedAddons = {
      ...addons,
      [addon.id]: (addons[addon.id] || 0) + 1
    };

    let updatedAddonOrder = [...(formData.addon_order || [])];

    if (!updatedAddonOrder.includes(addon.id)) {
      updatedAddonOrder.push(addon.id);
    }

    setAddons(updatedAddons);

    formData.addon_quantity = updatedAddons;
    formData.addon_order = updatedAddonOrder;
    formData.addons = updatedAddonOrder;
  };

  const handleRemoveClick = (addon) => {
    if (addons[addon.id] > 0) {
      const updatedAddons = { ...addons };
      updatedAddons[addon.id] -= 1;

      if (updatedAddons[addon.id] === 0) {
        // If quantity is reduced to 0, remove the addon from addon_quantity, addon_order, and addons
        delete updatedAddons[addon.id];
        const updatedAddonOrder = formData.addon_order.filter((id) => id !== addon.id);
        formData.addon_order = updatedAddonOrder;

        const updatedAddonsList = formData.addons.filter((id) => id !== addon.id);
        formData.addons = updatedAddonsList;
      }

      setAddons(updatedAddons); // Update the addons state directly
      formData.addon_quantity = updatedAddons; // Update the addon_quantity in your form data
    }
  };


  return (
    <div className="h-auto">
      <div className='max-w-[85rem] mx-auto '>
        <ScrollToTop />
        <div className='pb-4'>
          {event.addon_details && event.addon_details.some(value => value.is_active) ? (
            <>
              <h6 className='pb-2 font-bold'>Addon</h6>
              <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
                {event.addon_details
                  .filter(value => value.is_active && (
                    (priceApplied === 'SAARC' && value.saarc_price > 0) ||
                    (priceApplied === 'Nepali' && value.nepali_price > 0) ||
                    (priceApplied === 'International' && value.international_price > 0)
                  ))
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name'
                  .map((value) => (
                    <div key={value.id}>
                      <Card className="h-full" variant="outlined">
                        <div className='h-24 overflow-hidden cursor-pointer' onClick={() => handleOpenModal(value)}>
                          <img className='object-cover object-center'
                            src={`${MediaAPI}${value.medias[0]?.src}`}
                            loading="lazy"
                          />
                        </div>
                        <CardContent>
                          <div className='flex flex-col items-end h-full space-x-2 font-custom '>
                            <button className='w-full flex flex-col justify-between gap-2 text-left' variant="text" key={value.id} onClick={() => handleOpenModal(value)}>
                              <div className='text-left'>
                                <h6 className='font-semibold leading-6'>{value.name}</h6>
                              </div>
                            </button>
                            <div className='w-full flex flex-row justify-between'>
                              <div className='flex flex-col justify-between'>
                                <p className='text-xs hover:text-red-600 pb-2 cursor-pointer' type='button' onClick={() => handleOpenModal(value)}>View Details</p>
                                <div>
                                  <p className='text-xs text-gray-500'>{priceApplied === 'SAARC' ? 'SAARC' : priceApplied === 'Nepali' ? 'National' : 'International'}
                                  </p>
                                  <h6 className='font-semibold'>
                                    {
                                      priceApplied === 'SAARC'
                                        ? `Rs. ${value.saarc_price}`
                                        : priceApplied === 'Nepali'
                                          ? `Rs. ${value.nepali_price}`
                                          : `Rs. ${value.international_price}`
                                    }
                                  </h6>
                                </div>
                              </div>
                              <div className='flex flex-col gap-2'>
                                <button
                                  className={`w-full sm:w-auto whitespace-nowrap inline-flex justify-center items-center gap-x-3  bg-red-600 hover:bg-red-700 duration-300 text-white text-center text-xs border border-transparent  font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-2 px-4`}
                                  onClick={() => handleAddClick(value)} disabled={formData.category === ''}
                                >
                                  Add
                                </button>
                                <button
                                  className={`w-full sm:w-auto whitespace-nowrap inline-flex justify-center items-center gap-x-3 border-gray-400 hover:border-gray-800 hover:bg-gray-50 hover:text-gray-800 ease-in duration-200 text-xs text-center border border-transparent text-gray-600 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-2 px-4`}
                                  onClick={() => handleRemoveClick(value)} disabled={addons[value.id] <= 0}>
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  ))}
              </div>
            </>
          ) : null}

          <div className='pt-4'>
            <TextField
              helperText="You can mention about the accommodation, flight, allergies, or other important details to be noted"
              label="Note"
              variant="outlined"
              type='text'
              multiline
              fullWidth
              name='user_note'
              margin="normal"
              minRows={4}
              value={formData.user_note}
              onChange={handleChange}
            />
          </div>

        </div >
      </div >

      <div className='flex justify-between mt-6 '>
        <button
          className={`w-full sm:w-auto whitespace-nowrap inline-flex justify-center items-center gap-x-3 border-gray-400 hover:border-gray-800 hover:bg-gray-50 hover:text-gray-800 ease-in duration-200 text-center border border-transparent text-gray-600 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-2 px-4`}
          onClick={previousStep} >
          Previous
        </button>
      </div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-describedby={`modal-modal-description-${selectedAddon?.id}`}
        className='flex justify-center font-custom'
      >
        <div className='overflow-scroll overflow-x-hidden w-3/4 lg:w-1/2 px-10 py-2 my-20 lg:my-10 bg-white'>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={selectedAddon?.medias?.length}
            visibleSlides={1}
            infinite={1}
            isPlaying={1}
            currentSlide={0}
            isIntrinsicHeight={1}
          >
            <div className='relative py-8'>
              <ButtonBack className={`absolute top-[50%] left-4 z-20 text-white hover:text-red-600 ease-in duration-200 ${selectedAddon?.medias?.length < 2 ? "hidden" : ''}`} ><ArrowBackIosIcon /></ButtonBack>
              <Slider className="h-80 overflow-hidden flex items-center justify-center rounded-lg">
                {selectedAddon?.medias.map((image, index) => (
                  <Slide key={`test_${index}`}>
                    <img key={`test2_${index}`} className='h-80 w-full object-cover object-center' src={`${MediaAPI}${image.src}`} />
                  </Slide>
                ))}
              </Slider>
              <ButtonNext className={`absolute top-[50%] right-4 z-20 text-white  hover:text-red-600 ease-in duration-200 ${selectedAddon?.medias?.length < 2 ? "hidden" : ''}`}><ArrowForwardIosIcon /></ButtonNext>
            </div>
          </CarouselProvider>

          <div id={`modal-modal-description-${selectedAddon?.id}`}>

            <h5 className='font-bold'>{selectedAddon?.name}</h5>
            <div className='[&>ul]:text-sm [&>p]:text-sm [&>p]:leading-relaxed [&>ul]:leading-relaxed [&>ul]:list-disc [&>ul]:indent-2 [&>ul]:pl-4 py-4  '
              dangerouslySetInnerHTML={{ __html: selectedAddon?.description }}
            />
          </div>
        </div>
      </Modal>

    </div >
  );
}

export default AddonForm;
