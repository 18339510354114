import { React, useState } from 'react';
import { TextField, Button, MenuItem } from '@mui/material';
import ScrollToTop from '../ScrollToTop';
import countrylist from "../../constants/countrylist";
import DatePicker from '../datePicker';
import Grid from '@mui/material/Unstable_Grid2';
import { grey } from '@mui/material/colors';
import ClearIcon from '@mui/icons-material/Clear';

function PersonalInfoForm({ nextStep, formData, handleChange, handleCountryChange, event, handleDateChange, handleClickToClear }) {
  const [validation, setValidation] = useState({
    first_name: true,
    last_name: true,
    gender: true,
    email: true,
    phone_number: true,
    address: true,
    zip_code: true,
    city: true,
    country: true,
    organization: true,
    size_preference: true,
  });

  const handleFieldValidation = (fieldName, value) => {
    let isValid = true;
    switch (fieldName) {
      case 'phone_number':
        // Allow an optional "+" at the beginning, followed by digits
        isValid = /^(\+)?[0-9]+$/.test(value) && value.length >= 10;
        break;
      case 'email':
        isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        break;
      case 'zip_code':
        isValid = /^[0-9]+$/.test(value) && value.length <= 10;
        break;

      default:
        break;
    }

    return isValid;
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    const isValid = handleFieldValidation(name, value);

    setValidation((prevValidation) => ({
      ...prevValidation,
      [name]: isValid,
    }));

    handleChange(event);
  };

  const isFormValid = () => {
    // Check if any required field is empty or any validation state is false
    return (
      Object.values(validation).every((isValid) => isValid) &&
      Object.keys(formData).every((fieldName) => {
        if (['first_name', 'last_name', 'gender', 'email', 'phone_number', 'address', 'country', 'dob', 'size_preference'].includes(fieldName)) {
          const fieldValue = formData[fieldName];
          return typeof fieldValue === 'string' ? fieldValue.trim() !== '' : fieldValue !== undefined && fieldValue !== null;
        }
        return true; // For non-required fields, consider them as valid
      })
    );
  };

  return (
    <div className="h-auto">
      <div className='max-w-[85rem] mx-auto'>
        <ScrollToTop />

        <h1 className="text-3xl font-bold mb-4">Personal Information</h1>
        <div className='flex w-full justify-end'>
          <Button variant='outlined' size='small' style={{ color: grey[600], borderColor: grey[600] }} onClick={handleClickToClear}><ClearIcon fontSize='inherit' />&nbsp;Clear All</Button>
        </div>
        <Grid container spacing={2} columns={2}>
          <Grid xs={2} md={1}>
            <TextField
              helperText="Ex. Hari Bahadur"
              required
              label="First Name"
              variant="outlined"
              type='text'
              fullWidth
              name='first_name'
              margin="normal"
              value={formData.first_name}
              onChange={handleChange}
            />
          </Grid>

          <Grid xs={2} md={1}>
            <TextField
              helperText="Ex. Thapa"
              required
              label="Last Name"
              variant="outlined"
              fullWidth
              name='last_name'
              margin="normal"
              value={formData.last_name}
              onChange={handleChange}
            />
          </Grid>

          <Grid xs={2} md={1}>
            <TextField
              required
              select
              label="Gender"
              variant="outlined"
              fullWidth
              name='gender'
              margin="normal"
              value={formData.gender}
              onChange={handleChange}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </TextField>
          </Grid>

          <Grid xs={2} md={1}>
            <TextField
              label="Email"
              variant="outlined"
              name="email"
              fullWidth
              margin="normal"
              type="email"
              value={formData.email}
              onChange={handleFieldChange}
              required
              error={!validation.email}
              helperText={!validation.email ? 'Please enter a valid email address.' : ''}
            />
          </Grid>

          <Grid xs={2} md={1}>
            <TextField
              label="Phone number"
              variant="outlined"
              type="tel"
              name="phone_number"
              margin="normal"
              fullWidth
              value={formData.phone_number}
              onChange={handleFieldChange} // Use the modified handleChange function
              required
              error={!validation.phone_number}
              helperText={
                !validation.phone_number ? 'Phone number should contain only numbers and be at least 10 digits.' : ''
              }
            />
          </Grid>
          <Grid xs={2} md={1} marginTop={2}>
            <DatePicker
              margin="normal"
              label='Date of Birth'
              value={formData.dob}
              required
              name='dob'
              fullWidth
              onChange={handleDateChange}>
            </DatePicker>
          </Grid>
          <Grid xs={2} md={1}>
            <TextField
              helperText="Ex. Hallanchowk - 6, Pokhara, Nepal"
              required
              label="Address"
              variant="outlined"
              fullWidth
              name='address'
              margin="normal"
              value={formData.address}
              onChange={handleChange}
            />
          </Grid>
          {/* <Grid xs={2} md={1}>
            <TextField
              label="Zip code"
              type="text"
              variant="outlined"
              name="zip_code"
              fullWidth
              margin="normal"
              value={formData.zip_code}
              onChange={handleChange}
              maxLength={10}
            />
          </Grid> */}
          {/* <Grid xs={2} md={1}>
            <TextField
              label="City"
              variant="outlined"
              type="text"
              name="city"
              fullWidth
              margin="normal"
              value={formData.city}
              onChange={handleChange}
              maxLength={100}
            />
          </Grid> */}

          <Grid xs={2} md={1}>
            <TextField
              label="Nationality"
              variant="outlined"
              type="text"
              name="country"
              fullWidth
              required
              select
              margin="normal"
              value={formData.country}
              onChange={(event) => {
                handleChange(event);
                handleCountryChange(event); // Update selectedCountry in RegistrationPage
              }}
              maxLength={100}
            >
              {countrylist.map((country, index) => (
                <MenuItem key={index} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid xs={2} md={1}>
            <TextField
              label="Organization"
              type="text"
              fullWidth
              name="organization"
              variant='outlined'
              margin="normal"
              value={formData.organization}
              onChange={handleChange}
            />
          </Grid>

          <Grid xs={2} md={1}>
            <TextField
              helperText="This information is for reference in case any items are included in the event or as addons."
              select
              label="Size"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              name='size_preference'
              value={formData.size_preference}
              onChange={handleChange}
            >
              <MenuItem value="x-small">SX</MenuItem>
              <MenuItem value="small">S</MenuItem>
              <MenuItem value="medium">M</MenuItem>
              <MenuItem value="large">L</MenuItem>
              <MenuItem value="x-large">XL</MenuItem>
              <MenuItem value="xx-large">XXL</MenuItem>
            </TextField>
          </Grid>

          {event.accepted_indexes && Array.isArray(event.accepted_indexes) && event.accepted_indexes.map((indexObj, idx) => (
            <Grid key={idx} xs={2} md={1}>
              <TextField
                fullWidth
                key={idx}
                label={`${indexObj.index} ID`}
                type="text"
                name={`user_indexes.${indexObj.index}`}
                variant="outlined"
                margin="normal"
                value={formData.user_indexes[indexObj.index] || ''}
                onChange={handleChange}
              />
            </Grid>
          ))}

        </Grid>
        <div className='flex justify-end pt-4'>
          <div>
            <button
              className={`w-full sm:w-auto whitespace-nowrap inline-flex justify-center items-center gap-x-3 ${isFormValid() ? ' bg-red-600 hover:bg-red-700 duration-300 text-white' : 'bg-gray-200 text-gray-400'} text-center border border-transparent  font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-2 px-4`}
              onClick={nextStep} disabled={!isFormValid()}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfoForm;
