import { React, useEffect, useState } from 'react';
import { patchApi } from '../../api/index';
import { TextField, Button, MenuItem } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import countrylist from "../../constants/countrylist";
import DatePicker from '../datePicker';
import ThrowAlert from '../alert';
import Compressor from 'compressorjs';
import { format, parseISO } from 'date-fns';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const UserProfile = ({ user }) => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        gender: '',
        blood_group: '',
        country: '',
        zip_code: '',
        city: '',
        address: '',
        dob: '',
        emergency_name: '',
        emergency_contact: '',
        relation: '',
        indexes: {},
        preferred_size: '',
    });

    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            first_name: user.first_name || '',
            last_name: user.last_name || '',
            email: user.email || '',
            phone_number: user.phone_number || '',
            gender: user.gender || '',
            blood_group: user.blood_group || '',
            country: user.country || '',
            zip_code: user.zip_code || '',
            city: user.city || '',
            address: user.address || '',
            dob: user.dob || null,
            preferred_size: user.preferred_size || '',
            indexes: user?.indexes || {},
        }));
    }, [user]);

    const [validation, setValidation] = useState({
        first_name: true,
        last_name: true,
        gender: true,
        email: true,
        phone_number: true,
        address: true,
        zip_code: true,
        city: true,
        country: true,
        organization: true,
    });

    const handleFieldValidation = (fieldName, value) => {
        let isValid = true;
    
        switch (fieldName) {
            case 'phone_number':
                // Allow an optional "+" at the beginning, followed by digits
                isValid = /^(\+)?[0-9]+$/.test(value) && value.length >= 10;
                break;
            case 'email':
                isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                break;
            case 'zip_code':
                isValid = /^[0-9]+$/.test(value) && value.length <= 10;
                break;
    
            default:
                break;
        }
    
        return isValid;
    };
    

    const [imageUploadCompleted, setImageUploadCompleted] = useState(false);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            new Compressor(file, {
                quality: 0.6,
                maxWidth: 800,
                maxHeight: 600,
                success(result) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setFormData((prevValues) => ({
                            ...prevValues,
                            profile_image: reader.result,
                        }));
                        setImageUploadCompleted(true);
                    };
                    reader.readAsDataURL(result);
                },
                error(err) {
                    console.error(err.message);
                },
            });
        }
    };

    useEffect(() => {
        if (imageUploadCompleted) {
            handleUpdateProfile();
        }
    }, []);

    const handleFieldChange = (event) => {
        const { name, value } = event.target;

        const isValid = handleFieldValidation(name, value);

        setValidation((prevValidation) => ({
            ...prevValidation,
            [name]: isValid,
        }));

        handleChange(event);
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        const [fieldName, index] = name.split('.');

        if (fieldName === 'indexes' && index !== undefined) {
            setFormData((prevData) => ({
                ...prevData,
                indexes: {
                    ...prevData.indexes,
                    [index]: value,
                },
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const isFormValid = () => {
        return (
            Object.values(validation).every((isValid) => isValid) &&
            Object.keys(formData).every((fieldName) => {
                if (['first_name', 'phone_number', 'gender', 'address', 'country', 'preferred_size'].includes(fieldName)) {
                    const fieldValue = formData[fieldName];
                    return typeof fieldValue === 'string' ? fieldValue.trim() !== '' : fieldValue !== undefined && fieldValue !== null;
                }
                return true;
            })
        );
    };

    const handleUpdateProfile = () => {
        let data = { ...formData, dob: format(parseISO(formData.dob), 'yyyy-MM-dd') }
        
        if (isFormValid()) {
            patchApi(`/users/${user.uuid}/`, data)
                .then((response) => {
                    if (response) {
                        ThrowAlert.fire('', 'Profile Updated.', 'success')
                        window.location.reload()
                    } else {
                        ThrowAlert.fire('', 'Cannot update profile.', 'error')
                    }
                })
                .catch((error) => {
                    // Handle network or other unexpected errors
                    console.error('Error updating profile:', error);
                });
        } else {
            // Display an error message or prevent the API call
            console.error('Form validation failed. Please correct the errors.');
        }
    };

    const handleDateChange = (name, date) => {
        setFormData((prevState) => ({
            ...prevState,
            [name]: date
        }));
    };

    return (
        <>
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Profile</h1>
                </div>
            </header>
            <div className="mx-auto max-w-7xl px-2 py-10 lg:px-8 ">
            
                <div className='flex flex-row flex-wrap justify-center lg:justify-start'>
                    
                    <div className='basis-1/4 flex flex-col items-center'>
                        <div className='overflow-hidden flex justify-center'>
                            {user.profile_image ? (
                                <img className='object-center rounded-full object-cover w-40 h-40' src={user.profile_image} />
                            ) : (
                                <AccountCircleIcon sx={{ height: 160, width: 160 }} />
                            )}
                        </div>
                        <div className='py-4 flex flex-col items-center gap-2'>
                            <p className={` rounded-xl px-2 text-white text-xs  ${user.is_verified ? "bg-red-600  " : " bg-gray-700"} `}>{user.is_verified ? "Verified" : "Unverified"}</p>
                            <div className='flex flex-col items-center'>
                                <h5>{user.first_name} {user.last_name}</h5>
                                <h6>{user.email}</h6>
                            </div>
                            <div className='flex flex-row space-x-2'>
                                <Button
                                    variant="contained"
                                    component="label"
                                    size="small"
                                    disabled={!user.gender} // Check if user.gender has data
                                >
                                    Upload Image
                                    <input
                                        type="file"
                                        hidden
                                        onChange={handleImageChange}
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='basis-3/4 px-4'>
                    <p className='pb-2'><strong>Note:</strong> Please, update the form below for easy registration in future events.</p>
                        <Grid container spacing={2} columns={2}>
                            <Grid item xs={2} md={1}>
                                <TextField
                                    required
                                    label="First Name"
                                    variant="outlined"
                                    type='text'
                                    fullWidth
                                    name='first_name'
                                    margin="normal"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />
                            </Grid>


                            <Grid item xs={2} md={1}>
                                <TextField
                                    required
                                    label="Last Name"
                                    variant="outlined"
                                    fullWidth
                                    name='last_name'
                                    margin="normal"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={2} md={1}>
                                <TextField
                                    required
                                    select
                                    label="Gender"
                                    variant="outlined"
                                    fullWidth
                                    name='gender'
                                    margin="normal"
                                    value={formData.gender}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="male">Male</MenuItem>
                                    <MenuItem value="female">Female</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={2} md={1}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    name="email"
                                    fullWidth
                                    margin="normal"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleFieldChange}
                                    required
                                    error={!validation.email}
                                    helperText={!validation.email ? 'Please enter a valid email address.' : ''}
                                />
                            </Grid>

                            <Grid item xs={2} md={1}>
                                <TextField
                                    label="Phone number"
                                    variant="outlined"
                                    type="tel"
                                    name="phone_number"
                                    margin="normal"
                                    fullWidth
                                    value={formData.phone_number}
                                    onChange={handleFieldChange} // Use the modified handleChange function
                                    required
                                    error={!validation.phone_number}
                                    helperText={
                                        !validation.phone_number ? 'Phone number should contain only numbers and be at least 10 digits.' : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <TextField sx={{ width: '99%' }}
                                    required
                                    label="Address"
                                    variant="outlined"
                                    fullWidth
                                    name='address'
                                    margin="normal"
                                    value={formData.address}
                                    onChange={handleChange}
                                />
                            </Grid>
                            {/* <Grid item xs={2} md={1}>
                                <TextField
                                    label="Zip code"
                                    type="text"
                                    variant="outlined"
                                    name="zip_code"
                                    fullWidth
                                    margin="normal"
                                    value={formData.zip_code}
                                    onChange={handleChange}
                                    maxLength={10}
                                />
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <TextField
                                    label="City"
                                    variant="outlined"
                                    type="text"
                                    name="city"
                                    fullWidth
                                    margin="normal"
                                    value={formData.city}
                                    onChange={handleChange}
                                    maxLength={100}
                                />
                            </Grid> */}

                            <Grid item xs={2} md={1}>
                                <TextField
                                    label="Country"
                                    variant="outlined"
                                    type="text"
                                    name="country"
                                    fullWidth
                                    select
                                    margin="normal"
                                    value={formData.country}
                                    onChange={(event) => {
                                        handleChange(event);
                                        // Update selectedCountry in RegistrationPage
                                    }}
                                    maxLength={100}
                                >
                                    {countrylist.map((country, index) => (
                                        <MenuItem key={index} value={country.name}>
                                            {country.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={2} md={1} marginTop={2} >
                                <DatePicker
                                    required
                                    label='Date of Birth'
                                    variant="outlined"
                                    name='dob'
                                    value={formData.dob}
                                    onChange={handleDateChange} >
                                </DatePicker>
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <TextField
                                    required
                                    select
                                    label="Blood Group"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name='blood_group'
                                    value={formData.blood_group}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="unknown">I don't know</MenuItem>
                                    <MenuItem value="A+">A+</MenuItem>
                                    <MenuItem value="A-">A-</MenuItem>
                                    <MenuItem value="B+">B+</MenuItem>
                                    <MenuItem value="B-">B-</MenuItem>
                                    <MenuItem value="O+">O+</MenuItem>
                                    <MenuItem value="O-">O-</MenuItem>
                                    <MenuItem value="AB+">AB+</MenuItem>
                                    <MenuItem value="AB-">AB-</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid xs={2} md={1}>
                                <TextField
                                    helperText="This information is for reference in case any items are included in the event or as addons."
                                    select
                                    label="Size"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    margin="normal"
                                    name='preferred_size'
                                    value={formData.preferred_size}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="x-small">SX</MenuItem>
                                    <MenuItem value="small">S</MenuItem>
                                    <MenuItem value="medium">M</MenuItem>
                                    <MenuItem value="large">L</MenuItem>
                                    <MenuItem value="x-large">XL</MenuItem>
                                    <MenuItem value="xx-large">XXL</MenuItem>
                                </TextField>
                            </Grid>

                            {Object.entries(user.indexes).map(([indexObj, idx]) => (
                                <Grid item key={indexObj} xs={2} md={1}>
                                    <TextField
                                        fullWidth
                                        key={indexObj}
                                        label={`${indexObj} ID`}
                                        type="text"
                                        name={`indexes.${indexObj}`}
                                        variant="outlined"
                                        margin="normal"
                                        value={formData.indexes[indexObj] || ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Button sx={{ mt: 2 }}
                            variant="contained"
                            color="primary"
                            onClick={handleUpdateProfile}
                            disabled={!isFormValid()}
                        >
                            Update Profile
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserProfile;
