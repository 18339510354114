import React, { useState, useEffect } from 'react';
import { images } from '../../../constants';
import './Header.css';
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { get } from '../../../api/index';
import { MediaAPI } from '../../../api/_constant';
import { Link } from 'react-router-dom';

const sliderVariants = {
  incoming: direction => ({
    x: direction > 0 ? "100%" : "-100%",
    scale: 1.2,
    opacity: 0,
  }),
  active: { x: 0, scale: 1, opacity: 1 },
  exit: direction => ({
    x: direction > 0 ? "-100%" : "100%",
    scale: 1,
    opacity: 0.2,
  }),
};

const sliderTransition = {
  duration: 1,
  ease: [0.56, 0.03, 0.12, 1.04],
};

const Header = () => {

  const [highlightEvents, setHighlightEvents] = useState();
  useEffect(() => {
    get('/content/highlight-events/')
      .then((res) => {
        if (res) {
          setHighlightEvents(res);
        }
      })
      .catch((error) => {
        console.error('Error occurred while fetching data:', error);
      });
  }, []);

  const mainEventDetails = highlightEvents?.main_event_detail;
  const carouselEventDetails = highlightEvents?.carousel_events_details;

  const combinedEventDetails = [];
  combinedEventDetails.push(mainEventDetails);
  // Iterate through carousel event details and add them to the array
  for (const eventId in carouselEventDetails) {
    if (carouselEventDetails.hasOwnProperty(eventId) && carouselEventDetails[eventId].id !== mainEventDetails.id) {
      combinedEventDetails.push(carouselEventDetails[eventId]);
    }
  }

  const [[imageCount, direction], setImageCount] = useState([0, 0]);

  const activeImageIndex = wrap(0, combinedEventDetails.length, imageCount);

  const swipeToImage = swipeDirection => {
    setImageCount([imageCount + swipeDirection, swipeDirection]);
  };

  const dragEndHandler = dragInfo => {
    const draggedDistance = dragInfo.offset.x;
    const swipeThreshold = 100;
    if (draggedDistance > swipeThreshold) {
      swipeToImage(-1);
    } else if (draggedDistance < -swipeThreshold) {
      swipeToImage(1);
    }
  };
  const skipToImage = imageId => {
    let changeDirection;
    if (imageId > activeImageIndex) {
      changeDirection = 1;
    } else if (imageId < activeImageIndex) {
      changeDirection = -1;
    }
    setImageCount([imageId, changeDirection]);
  };

  const incrementActiveIndex = () => {
    setImageCount([imageCount + 1, 1]);
  };

  useEffect(() => {
    const interval = setInterval(incrementActiveIndex, 7000); // Change image every 5 seconds
    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [imageCount]);
  

  return (
    <main className='flex items-center min-h-screen text-white '>
      <div className="slider-container font-custom text-white">
        <div className="slider">
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={imageCount}
              style={{
                backgroundImage: `url("${MediaAPI}${combinedEventDetails[activeImageIndex]?.headline_image}")`,
              }}

              custom={direction}
              variants={sliderVariants}
              initial="incoming"
              animate="active"
              exit="exit"
              transition={sliderTransition}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(_, dragInfo) => dragEndHandler(dragInfo)}
              className="image"
            >
              <div className='max-w-[85rem] mx-auto flex items-center min-h-screen text-white '>
                <div className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-[#09090b] via-transparent "></div>
                <motion.div
                  initial={{ opacity: 0, y: -5, scale: 0.9 }}
                  animate={{
                    opacity: 1,
                    y: 1,
                    scale: 1,
                    transition: { delay: 0.5, duration: 0.3 },
                  }}
                  exit={{ opacity: 0 }}
                  className="absolute m-10 lg:m-20 font-custom text-white"
                >
                  <motion.p
                    initial={{ opacity: 0, y: -5 }}
                    animate={{ opacity: 1, y: 0, transition: { delay: 0.7, duration: 0.3 } }}
                  >
                    {combinedEventDetails[activeImageIndex]?.start_datetime && (
                      <div className='flex items-center space-x-2'>
                        <div>
                          <img className='object-fit w-4' src={images.calendar} alt="" />
                        </div>
                        <div>
                          <h5 className="text-lg">
                            {new Date(combinedEventDetails[activeImageIndex]?.start_datetime).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', timeZone: 'Asia/Kathmandu' })}
                          </h5>
                        </div>
                      </div>
                    )}
                  </motion.p>
                  <motion.h1
                    initial={{ opacity: 0, y: -5 }}
                    animate={{ opacity: 1, y: 0, transition: { delay: 0.9, duration: 0.3 } }}
                    className="font-extrabold text-2xl md:text-4xl lg:text-6xl uppercase"
                  >
                    {combinedEventDetails[activeImageIndex]?.name}
                  </motion.h1>
                  <motion.h5
                    initial={{ opacity: 0, y: -5 }}
                    animate={{ opacity: 1, y: 0, transition: { delay: 0.9, duration: 0.3 } }}
                    className="text-sm md:text-base lg:text-lg"
                  >
                    <div className='col-span-1 row-span-1 flex flex-wrap flex-col sm:flex-row items-start sm:space-x-8 pl-1'>


                      {combinedEventDetails[activeImageIndex]?.category_details && combinedEventDetails[activeImageIndex]?.category_details.length > 0 && (
                        <div className='flex items-center space-x-2'>
                          <div>
                            <img className='object-fit w-4' src={images.runner} alt='' />
                          </div>
                          <div>
                            <h5 className="text-lg">
                              {combinedEventDetails[activeImageIndex]?.category_details.reduce((acc, category, index) => {
                                if (index === 0) {
                                  return category.name;
                                } else if (index === combinedEventDetails[activeImageIndex]?.category_details.length - 1) {
                                  return `${acc} & ${category.name}`;
                                } else {
                                  return `${acc}, ${category.name}`;
                                }
                              }, '')}
                            </h5>
                          </div>
                        </div>
                      )}

                      {combinedEventDetails[activeImageIndex]?.general_area && (
                        <div className='flex items-center space-x-2'>
                          <div>
                            <img className='object-fit w-4' src={images.location} alt="" />
                          </div>
                          <div>
                            <h5 className="text-lg">{combinedEventDetails[activeImageIndex]?.general_area}</h5>
                          </div>
                        </div>
                      )}
                    </div>
                  </motion.h5>
                  <motion.button
                    initial={{ opacity: 0, y: -5 }}
                    animate={{ opacity: 1, y: 0, transition: { delay: 1.1, duration: 0.3 } }}
                    className="mr-2 mt-4 rounded-sm uppercase p-2  border-2 text-xs md:text-sm lg:text-base hover:bg-transparent hover:border-red-600 active:text-red-600 duration-300"
                  >
                    <Link to={`events/${combinedEventDetails[activeImageIndex]?.slug}`}>
                      Learn More
                    </Link>
                  </motion.button>

                  {combinedEventDetails[activeImageIndex]?.is_registration_open ?
                    <motion.button
                      initial={{ opacity: 0, y: -5 }}
                      animate={{ opacity: 1, y: 0, transition: { delay: 1.2, duration: 0.3 } }}
                      className="uppercase rounded-sm p-2 border-2 border-red-600 text-xs md:text-sm lg:text-base bg-red-600 font-bold hover:bg-transparent hover:border-2 active:text-red-600 duration-300"
                    >
                      <Link to={`events/${combinedEventDetails[activeImageIndex]?.slug}/register`}>
                        Register Now!
                      </Link>
                    </motion.button>
                    : null
                  }

                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: -5 }}
                  animate={{ opacity: 1, y: 0, transition: { delay: 1.3, duration: 0.3 } }}
                  className="hidden absolute md:grid grid-cols-12 m-10 lg:m-20 bottom-0 ">
                  <div className="col-span-12 md:col-span-4 text-xs md:text-sm lg:text-base gap-3 flex justify-center items-center">
                    <img
                      className="float-left object-contain h-20 w-20"
                      src={`${MediaAPI}${combinedEventDetails[activeImageIndex]?.logo}`}
                      alt="logo-event"
                    />

                    <div className='overflow-hidden max-h-[3em] line-clamp-3'>
                      <div className='hidden md:block [&_p]:text-xs' dangerouslySetInnerHTML={{ __html: combinedEventDetails[activeImageIndex]?.description }} />
                    </div>
                  </div>
                  <div className="md:block md:col-span-4 flex justify-center items-end"></div>
                </motion.div>
              </div>
            </motion.div>

          </AnimatePresence>
        </div>
        <div className="hidden md:flex absolute bottom-16  right-20 w-1/2 overflow-scroll overflow-x-hidden overflow-y-hidden justify-center">
          <motion.div
            className="carousel-content"
          >
            {combinedEventDetails?.map((event, index) => (
              <div
                key={index}
                onClick={() => skipToImage(index)}
                className={`carousel-item flex flex-col space-y-1 hover:bg-red-600 ease-in hover:text-white p-4 ${index === activeImageIndex ? "active" : ""}`}
              >
                <small className='text-[10px] leading-none'>{new Date(event?.start_datetime).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric', timeZone: 'Asia/Kathmandu' })}</small>
                <h6 className='leading-none uppercase font-semibold'>{event?.name}</h6>
              </div>
            ))}
          </motion.div>

        </div>


      </div>

    </main>
  );
};

export default Header;
