import React from 'react';
import { MediaAPI } from '../../../api/_constant';

const EventRoute = ({ event }) => {
  return (
    <>
      {event && event.category_details ? (
        <section id='route' className='scroll-mt-32 pb-10'>
          <h5 className='font-extrabold tracking-wide pb-4'>Route</h5>
          {event.header_route_image ? (
            <img className='pb-10' src={event.header_route_image} alt="" />
          ) : null}

          <nav className="flex space-x-2" aria-label="Tabs" role="tablist">
            {event.category_details.map((item, index) => (
              <button
                type="button"
                key={index}
                className={`${index === 0 ? 'active' : ''} hs-tab-active:bg-red-600 rounded-md hs-tab-active:text-white py-2 px-4 inline-flex items-center gap-2 bg-transparent text-sm font-medium text-center text-gray-500 transition ease-in duration-150 hover:text-red-600`}
                id={`pills-with-brand-color-item-${index}`}
                data-hs-tab={`#pills-with-brand-color-${index}`}
                aria-controls={`pills-with-brand-color-${index}`}
                role="tab"
              >
                {item.name}
              </button>
            ))}
          </nav>
          <div className="mt-3 ">
            {event.category_details.map((item, index) => (
              <div key={index} id={`pills-with-brand-color-${index}`} role="tabpanel" aria-labelledby={`pills-with-brand-color-item-${index}`} className={`${index !== 0 ? 'hidden' : ''}`}>
                <div>
                  <div className={item.description ? 'py-2 [&>ul]:text-sm [&>p]:text-sm [&>p]:leading-relaxed [&>ul]:leading-relaxed [&>ul]:list-disc [&>ul]:indent-2 ' : ''} dangerouslySetInnerHTML={{ __html: item.description }}>
                  </div>
                  {item.extra_requirements ? (
                    <div className='py-4'>
                      <p className='text-sm'><strong>Extra Requirements:</strong> {item.extra_requirements}</p>
                    </div>
                  ) : null}

                  <div className='flex  flex-col-reverse flex-wrap mt-2 text-sm leading-relaxed'>

                    {item.gpx_file ? (
                      <button className='text-center col-span-2 border-2 py-2 mt-4  duration-300  hover:bg-black hover:text-white'
                        onClick={() => {
                          if (item.gpx_file) {
                            const url = `${MediaAPI}${item.gpx_file}`
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            a.download = 'your-gpx-file.gpx'; // Replace with the desired filename
                            document.body.appendChild(a);
                            a.click();
                            URL.revokeObjectURL(url);
                            document.body.removeChild(a);
                          }
                        }}>
                        <h6 className='uppercase'>Download GPX</h6>
                      </button>) : null}

                    {item.headline_image ? (
                      <div className='basis-full'>
                        <img className=' w-full' src={`${MediaAPI}${item.headline_image}`} alt={item.name} />
                      </div>) : null}

                    <div className="basis-full grid grid-cols-2 content-between mb-8 gap-y-4 ">
                      {item.actual_distance > 0 ? (
                        <div>
                          <sub className='uppercase'>Actual distance</sub>
                          <p className='text-sm font-semibold'>{item.actual_distance}KM</p>
                        </div>
                      ) : null}

                      {item.elevation_gain !== 0 && item.elevation_lost !== 0 && (
                        <div>
                          <sub className='uppercase'>Elevation Gain/Loss</sub>
                          <p className='text-sm font-semibold'>
                            {parseFloat(item.elevation_gain).toFixed(0)}m / -{parseFloat(item.elevation_lost).toFixed(0)}m
                          </p>
                        </div>
                      )}

                      {item.highest_altitude !== 0 && (
                        <div>
                          <sub className='uppercase'>Maximum Elevation</sub>
                          <p className='text-sm font-semibold'>{parseFloat(item.highest_altitude).toFixed(0)} ft</p>
                        </div>
                      )}


                      {item.number_of_checkpoints > 0 ? (
                        <div>
                          <sub className='uppercase'>Checkpoints</sub>
                          <p className='text-sm font-semibold'>{item.number_of_checkpoints}</p>
                        </div>) : null}

                      <div className='col-span-2 '>
                        <sub className='uppercase'>Cut-off Details</sub>
                        <p className='text-sm font-semibold'>{item.cut_off_description}</p>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default EventRoute;