import react, { useEffect, useState } from 'react';
import { patchApi, postApi } from '../api';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { signInAsync } from '../authRedux/store';

const PasswordReset = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState({
        email: "",
        code: '',
        password: '',
        confirm_password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [errors, setErrors] = useState({
        email: '',
        password: '',
        confirm_password: '',
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const emailParam = searchParams.get('email');

        if (emailParam) {
            setFormData((prevData) => ({
                ...prevData,
                email: emailParam,
            }));
        }
    }, [location.search]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation logic (same as before)
        const newErrors = {};
        if (formData.password.length < 8) {
            newErrors.password = 'Password must be at least 8 characters long';
        }
        if (formData.password !== formData.confirm_password) {
            newErrors.confirm_password = 'Passwords do not match';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const response = await postApi('/users/password/reset/', {
                    email: formData.email,
                    code: formData.code,
                    password: formData.password,
                });

                if (response) {
                    e.preventDefault();
                    try {
                        await dispatch(signInAsync(formData.email, formData.password));
                        const from = location.state?.from || '/dashboard';
                        navigate(from);

                    } catch (error) {
                        console.error('Password Reset failed:', error);
                        e.preventDefault();
                    }
                }
            } catch (error) {
                console.error('Password Reset failed:', error);
            }
        }
    }

    return (
        <>
            <div className="pt-20 font-custom">
                <div className="flex h-full items-center py-16">
                    <main className="w-full max-w-md mx-auto p-6">
                        <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm w-5/6">
                            <div className="p-4 sm:p-7">
                                <div className="text-center">
                                    <h1 className="block text-2xl font-bold text-gray-800 pb-5">Reset Password</h1>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="grid gap-y-4">
                                        <div>
                                            <label className="block text-sm mb-2 ">Email address</label>
                                            <div className="relative">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-red-600 focus:ring-red-600"
                                                    required
                                                    aria-describedby="email-error"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="block text-sm mb-2 ">Verification Code</label>
                                            <div className="relative">
                                                <input type="code"
                                                    id="code"
                                                    name="code"
                                                    className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
                                                    required
                                                    aria-describedby="code-error"
                                                    onChange={handleChange}
                                                    value={formData.code} />
                                                <div className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                    <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label className="block text-sm mb-2 ">Password</label>
                                            <div className="relative">
                                                <input type="password"
                                                    id="password"
                                                    name="password"
                                                    className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
                                                    required
                                                    aria-describedby="password-error"
                                                    onChange={handleChange}
                                                    value={formData.password} />
                                                <div className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                    <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            {errors.password && <p className="text-xs text-red-600 mt-2">{errors.password}</p>}
                                        </div>

                                        <div>
                                            <label className="block text-sm mb-2 ">Confirm Password</label>
                                            <div className="relative">
                                                <input type="password"
                                                    id="confirm_password"
                                                    name="confirm_password"
                                                    className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
                                                    required
                                                    aria-describedby="confirm-password-error"
                                                    onChange={handleChange}
                                                    value={formData.confirm_password} />
                                                <div className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                    <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            {errors.confirm_password && <p className="text-xs text-red-600 mt-2">{errors.confirm_password}</p>}
                                        </div>
                                        <button type="submit" className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-600 text-white hover:bg-red-700 ease-in duration-200 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 transition-all text-sm">Reset Password</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}
export default PasswordReset;