import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { initializeAuth } from './authRedux/store';
import { Footer, NavigationBar } from './components';
import ScrollToTop from './components/ScrollToTop';
import RegistrationPage from './pages/RegistrationPage';
import UserDashboard from './pages/UserDashboard';
import UserLogin from './pages/UserLogin';
import EventDetail from './pages/event';
import EventListing from './pages/event_listing';
import HomePage from './pages/homepage';
import BlogsArchive from './pages/BlogsArchive';
import BlogPage from './pages/BlogPage';
import About from './pages/About';
import './App.css';
import Invoice from './pages/Invoice';
import { setAttemptedRoute } from './authRedux/authActions';
import { ThemeProvider, createTheme } from '@mui/material/styles'; 
import { Password } from '@mui/icons-material';
import PasswordReset from './pages/PasswordReset';
import EmailVerified from './pages/EmailVerified';

import('preline');

const App = () => {
  const location = useLocation();
  const showNavigationBar = !location.pathname.startsWith('/dashboard');
  const showFooter = !location.pathname.startsWith('/dashboard');
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const attemptedRoute = useSelector(state => state.auth.attemptedRoute);
  const dispatch = useDispatch();
  const [isAuthenticatedLoaded, setIsAuthenticatedLoaded] = useState(false);

  const checkAuthentication = async () => {
    // Replace this with your actual authentication logic
    return new Promise((resolve) => {
      setTimeout(() => {
        const isAuthenticated = true; // Simulated authentication
        resolve(isAuthenticated);
      }, 1000);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const isAuthenticated = await checkAuthentication();
      setIsAuthenticatedLoaded(true);
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Initialize authentication when the app mounts
    dispatch(initializeAuth()).then(() => {
      // Any additional initialization steps can be placed here
    });
  }, [dispatch]);

  if (!isAuthenticatedLoaded) {
    // You can show a loading spinner or some other UI while checking authentication
    return <div className="progress-loader">
      <div className="progress"></div>
    </div>;
  }

  if (isAuthenticated && attemptedRoute) {
    // Clear the attempted route from the store
    dispatch(setAttemptedRoute(null));
    // Redirect to the attempted route
    return <Navigate to={attemptedRoute} replace={true} />;
  }

  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins, sans-serif', 
    },
  });
  

  return (
    <ThemeProvider theme={theme}>
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <ScrollToTop />
        {showNavigationBar && <NavigationBar />}
        <Routes location={location} key={location.pathname}>
          <Route path='/password-reset' element={<PasswordReset />} />
          <Route path='/email-verification' element={< EmailVerified/>}/>
          <Route path="/" element={<HomePage />} />
          <Route path="/events/:slug" element={<EventDetail />} />
          <Route path="/events" element={<EventListing />} />
          <Route path="/blogs" element={<BlogsArchive />} />
          <Route path="/blogs/:slug" element={<BlogPage />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/login"
            element={isAuthenticated ? <Navigate to="/dashboard"/> : <UserLogin />} />

          <Route
            path="/invoice/:uuid"
            element={isAuthenticated ? <Invoice /> : <Navigate to="/login" state={{ from: location }} />}
          />

          <Route
            path="/events/:slug/register"
            element={isAuthenticated ? <RegistrationPage /> : <Navigate to="/login" state={{ from: location }} />}
          />

          <Route
            path="/dashboard"
            element={isAuthenticated ? <UserDashboard /> : <Navigate to="/login" state={{ from: location }} />}
          />

        </Routes>
      </div>

      {showFooter && <div className="mt-auto"><Footer /></div>}
    </div>
    </ThemeProvider>
  );
};

export default App;
