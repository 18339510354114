import React from 'react';
import { Chip } from '@mui/material';
import { images } from '../../../constants';

const EventOverview = ({ event }) => {

  return (
    <section id='overview' className='scroll-mt-32 pb-10  font-custom'>
      <div className='flex sm:flex-nowrap flex-wrap flex-row justify-between items-center'>
          <div className='w-full md:w-2/3'>
            <div className='-m-1 mb-2'>
              {event.event_tags_details && Array.isArray(event.event_tags_details) ? (
                event.event_tags_details.map((tag) => (
                  <Chip key={tag.id} label={tag.name} variant="outlined" className='m-1 ' />
                ))
              ) : [null]}
            </div>
            <div className='pb-4'>
              <table className="w-full divide-gray-200">
                <tbody className=" divide-gray-200 ">
                  {event.course_profile && (
                    <tr>
                      <td className="py-1 text-left text-sm">Course Profile</td>
                      <td className="py-1 text-left text-sm font-semibold">{event.course_profile}</td>
                    </tr>
                  )}

                  {event.event_type_detail && event.event_type_detail.name && (
                    <tr>
                      <td className="py-1 text-left text-sm">Event Type</td>
                      <td className="py-1 text-left text-sm font-semibold">
                        {event.event_type_detail.name}
                      </td>
                    </tr>
                  )}

                  {event.starts_from_location && (
                    <tr>
                      <td className="py-1 text-left text-sm">Start Location</td>
                      <td className="py-1 text-left text-sm font-semibold">{event.starts_from_location}</td>
                    </tr>
                  )}

                  {event.ends_at_location && (
                    <tr>
                      <td className="py-1 text-left text-sm">End Location</td>
                      <td className="py-1 text-left text-sm font-semibold">{event.ends_at_location}</td>
                    </tr>
                  )}

                  {event.accepted_indexes && Array.isArray(event.accepted_indexes) && (
                    <tr>
                      <td className="py-2 text-left text-sm">Points/Indexes</td>
                      <td className="py-2 text-left text-sm font-semibold">
                        {event.accepted_indexes.map(item => item.index).join(', ')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

            {event.accepted_indexes && Array.isArray(event.accepted_indexes) && (
              <div className='w-full md:w-1/3 flex flex-row md:flex-col items-center md:items-end gap-4'>
                {event.accepted_indexes.map(item => (
                  <div className='flex flex-row md:flex-col md:items-end pb-2' key={item.id}>
                    {item.index === 'ITRA' && (
                      <img className='object-contain mb-4 md:mb-0 h-14 md:h-16' src={images.itra} alt="ITRA" />
                    )}
                    {item.index === 'UTMB' && (
                      <img className='object-contain h-8 md:h-12' src={images.utmb} alt="UTMB" />
                    )}
                  </div>
                ))}
              </div>
            )}

        </div>

      {event.description && event.description.trim() !== "" && (
        <div>
          <div className='[&>p]:text-sm [&>p]:leading-relaxed' dangerouslySetInnerHTML={{ __html: event.description }} />
        </div>
      )}
    </section>
  );
};

export default EventOverview;
