import React, { useState } from 'react';
import { TextField, MenuItem, Grid } from '@mui/material';
import ScrollToTop from '../ScrollToTop';


function EmergencyInfo({ nextStep, previousStep, formData, handleChange, event, user }) {

  const [validation, setValidation] = useState({
    blood_group: true,
    emergency_name: true,
    emergency_contact: true,
    relation: true,
  });

  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    const isValid = handleFieldValidation(name, value);

    setValidation((prevValidation) => ({
      ...prevValidation,
      [name]: isValid,
    }));

    handleChange(event);
  };

  const handleFieldValidation = (fieldName, value) => {
    let isValid = true;
    // Perform validation for the specific field
    switch (fieldName) {
      case 'emergency_contact':
        isValid = /^[0-9]+(\s*)$/.test(value) && value.length >= 9 && value.length < 16;
        break;
      default:
        break;
    }

    return isValid;
  };

  const isFormValid = () => {
    // Check if any required field is empty or any validation state is false
    return (
      Object.values(validation).every((isValid) => isValid) &&
      Object.keys(formData).every((fieldName) => {
        if (['blood_group', 'emergency_name', 'emergency_contact', 'relation'].includes(fieldName)) {
          const fieldValue = formData[fieldName];
          return typeof fieldValue === 'string' ? fieldValue.trim() !== '' : fieldValue !== undefined && fieldValue !== null;
        }
        return true; // For non-required fields, consider them as valid
      })
    );
  };

  return (
    <div className="h-auto">
      <div className='max-w-[85rem] mx-auto'>
        <ScrollToTop />

        <h1 className="text-3xl font-bold mb-4">Emergency Information</h1>
        <Grid container spacing={2} columns={2}>
          <Grid item xs={2} md={1}>
            <TextField
              select
              label="Blood Group"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              name='blood_group'
              value={formData.blood_group}
              onChange={handleChange}
            >
              <MenuItem value="unknown">I don't know</MenuItem>
              <MenuItem value="A+">A+</MenuItem>
              <MenuItem value="A-">A-</MenuItem>
              <MenuItem value="B+">B+</MenuItem>
              <MenuItem value="B-">B-</MenuItem>
              <MenuItem value="O+">O+</MenuItem>
              <MenuItem value="O-">O-</MenuItem>
              <MenuItem value="AB+">AB+</MenuItem>
              <MenuItem value="AB-">AB-</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={2} md={1}>
            <TextField
              required
              helperText="Ex. Hari Bahadur Thapa"
              label="Emergency Contact - Full Name"
              variant="outlined"
              fullWidth
              name='emergency_name'
              margin="normal"
              value={formData.emergency_name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={2} md={1}>
            <TextField
              required
              label="Emergency Contact - Phone Number"
              variant="outlined"
              fullWidth
              margin="normal"
              name='emergency_contact'
              value={formData.emergency_contact}
              onChange={handleFieldChange}
            />
          </Grid>

          <Grid item xs={2} md={1}>
            <TextField
              required
              label="Emergency Contact - Relation"
              helperText="Ex. Father, Friend, Brother, etc."
              variant="outlined"
              fullWidth
              margin="normal"
              name='relation'
              value={formData.relation}
              onChange={handleChange}
            />
          </Grid>

        </Grid>

        <div className='flex justify-between mt-6'>
          <button
            className={`w-full sm:w-auto whitespace-nowrap hover:bg-gray-50 inline-flex justify-center items-center gap-x-3 border-gray-400 hover:border-gray-800 hover:text-gray-800 ease-in duration-200 text-center border border-transparent text-gray-600 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-2 px-4`}
            onClick={previousStep} >
            Previous
          </button>

          <button
            className={`w-full sm:w-auto whitespace-nowrap inline-flex justify-center items-center gap-x-3 ${isFormValid() ? ' bg-red-600 hover:bg-red-700 duration-300 text-white' : 'bg-gray-200 text-gray-400'} text-center border border-transparent  font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-2 px-4`}
            onClick={nextStep} disabled={!isFormValid()}>
            Next
          </button>
        </div>

      </div>
    </div>
  );
}

export default EmergencyInfo;
