import React from 'react';

const EventDetailsNav = ({ event, gallery }) => {
  if (!event || !event.category_details) {
    return null;
  }

  // Filter categories that have at least one prize with a prize.id
  const categoriesWithPrizes = event.category_details.filter(
    (category) => category.prizes_details.some((prize) => prize.id)
  );

  // Check if there are any categories with prizes to render
  const hasPrizes = categoriesWithPrizes.length > 0;

  return (
    <div className='hidden md:block bg-[#2f353e] px-10 sticky top-16 lg:px-20 z-20'>
      <div className='max-w-[85rem] mx-auto'>
        <nav className='font-custom text-white font-semibold'>
          <ul className='flex flex-wrap h-8 uppercase justify-between '>
            <li className="w-1/8 hover:text-red-600 ease-in duration-200">
              <sub>
                <a href="#overview">Overview</a>
              </sub>
            </li>

            {gallery && gallery.results && gallery.results.length > 0 ? (
              <li className="w-1/8 hover:text-red-600 ease-in duration-200">
                <sub>
                  <a href="#gallery">Gallery</a>
                </sub>
              </li>
            ) : null}
            {event && event.category_details && event.category_details.length > 0 ? (
              <li className="w-1/8 hover:text-red-600 ease-in duration-200">
                <sub>
                  <a href="#route">Route</a>
                </sub>
              </li>
            ) : null}
            {event && event.schedule_details && event.schedule_details.length > 0 ? (
              <li className="w-1/8 hover:text-red-600 ease-in duration-200">
                <sub>
                  <a href="#schedule">Schedule</a>
                </sub>
              </li>
            ) : null}
            {event &&
              <li className="w-1/8 hover:text-red-600 ease-in duration-200">
                <sub>
                  <a href="#pricing">Pricing</a>
                </sub>
              </li>
            }
            {hasPrizes > 0 ? (
              <li className="w-1/8 hover:text-red-600 ease-in duration-200">
                <sub>
                  <a href="#prize">Prize</a>
                </sub>
              </li>
            ) : null}

            {event && (event.things_required_to_bring || event.things_recommended_to_bring) ? (
              <li className="w-1/8 hover:text-red-600 ease-in duration-200">
                <sub>
                  <a href="#whattobring">What to bring?</a>
                </sub>
              </li>
            ) : null}

            {event && event.faq_details && event.faq_details.length > 0 ? (
              <li className="w-1/8 hover:text-red-600 ease-in duration-200">
                <sub>
                  <a href="#FAQ">FAQ</a>
                </sub>
              </li>
            ) : null}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default EventDetailsNav;
