import React from "react";
import "./homepage.css";

import { About, Events, Header, Trusted } from '../components/container';
import Testimonials from "../components/container/Testimonials/Testimonials";

const HomePage = () => {
  return (
    <>
      <div className="w-full overflow-clip">
        <Header />
        <Trusted />
        <About />
        <Events />
        <Testimonials />
      </div>
    </>
  );
}

export default HomePage;

