import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentComponent from "../../src/components/stripePayment";
import { get, postApi } from "../api";
import "./Invoice.css";
import { images } from "../constants";
import QRCode from "qrcode";
import { Grid } from "@mui/material";

const stripePromise = loadStripe("pk_live_51Nrz2GDgntm666AJEHmVli5m0Jbx3HjuQGUMBBsOv1yYMTwqvbwoqIEH1vuErJCN8VjYgusi8sw6CoaWx4kOOMUr00ZkEWJv2y");

const Invoice = () => {
    const { uuid } = useParams();
    const [invoice, setInvoice] = useState([]);

    useEffect(() => {
        get(`/sales/invoices/${uuid}/`).then((res) => {
            setInvoice(res);
        });
    }, [uuid]);

    //Stripe
    const [stripeClientSecret, setStripeClientSecret] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false);

    const options = {
        clientSecret: stripeClientSecret
    };

    const handleStripePayment = (invoiceUUID) => {
        // Check if payment is already in progress, and return if it is
        if (paymentInProgress) {
            return;
        }

        // Set the flag to indicate that the payment process is in progress
        paymentInProgress = true;

        setIsSubmitting(true);
        get(`/sales/invoices/${invoiceUUID}/initiate_stripe_payment/`).then((res) => {
            if (res) {
                setStripeClientSecret(res['clientSecret']);
                setIsSubmitting(false);
                setQRData(true);
                // Reset the payment in progress flag when the payment process is completed
                paymentInProgress = false;

            }
        });
    };

    const handleStripePaymentClose = (invoiceUUID) => {
        // Check if the payment process is in progress, and return if it is
        if (paymentInProgress) {
            return;
        }

        setIsSubmitting(false); // Reset isSubmitting to false
        setStripeClientSecret(null); // Reset stripeClientSecret to null (or initial value)
        setQRData(false); // Reset QRData to false (or initial value)
    };


    //FonePay
    const [QRData, setQRData] = useState(null);
    const [msg, setMsg] = useState('Scan to pay.')
    const itemsPerPage = 10;
    let paymentInProgress = false;

    const _generateQRPopup = (data) => {
        QRCode.toDataURL(data)
            .then((url) => {
                setQRData(url);
            })
            .catch((err) => {
                console.error(err);
            });
    };
  
    const handleBankTransfer = async (invoiceUUID) => {
        let invoiceid = invoiceUUID;
    }

    const handleFonePayPayment = async (invoiceUUID) => {
        const postValues = {
            "amount": invoice.total_bill_amount - invoice.paid_amount
        };

        await postApi(`/sales/invoices/${invoiceUUID}/initiate_fonepay/`, postValues).then((response) => {

            let socket = new WebSocket(
                response["thirdpartyQrWebSocketUrl"]

            );
            socket.onopen = function (e) {
                _generateQRPopup(response["qrMessage"]);
            };
            socket.onmessage = async function (event) {
                console.log("QR is scanned");
                setMsg('QR is scanned. Processing . . .')

                let _data = JSON.parse(event.data);
                let deviceId = _data.deviceId;

                let isPaymentDone = JSON.parse(_data.transactionStatus);

                isPaymentDone =
                    isPaymentDone &&
                    isPaymentDone.paymentSuccess &&
                    isPaymentDone.amount &&
                    isPaymentDone.amount > 0;

                if (isPaymentDone) {
                    //verify... then
                    const _dataToSend = {
                        fonepay_payment_id: response["fonepay_payment_id"],
                        deviceId: deviceId,
                    };
                    setMsg('Payment is Done, Verifying . . . ')
                    await postApi(`/sales/invoices/${invoiceUUID}/verify_fonepay/`, _dataToSend).then((res) => {
                        if (res['status']) {
                            setMsg('Verified.')
                            setQRData(null)
                            alert('Payment Successful.')

                            setTimeout(function () {
                                window.location.reload();
                            }, 1000);
                            socket.close();
                        } else {
                            alert('Payment Failed.')
                            socket.close();
                        }
                    });
                }
            };

            socket.onclose = function (event) {
                setQRData(false);
                console.log("Websocket closed");
            };
        })

    }

    const [billData, setBillData] = useState([]);

    useEffect(() => {
        if (invoice.tickets) {
            const updatedBillData = invoice.tickets?.map((ticket) => {
                const addons = ticket.addons?.map((addonId) => {
                    const addonDetail = ticket.addon_details.find(
                        (addon) => addon.id === addonId
                    );
                    const quantity = ticket.addon_quantity[addonId] || 0;
                    return addonDetail
                        ? {
                            id: addonDetail.id,
                            name: addonDetail.name,
                            price:
                                ticket.price_applied === 'nepali'
                                    ? parseFloat(addonDetail.nepali_price)
                                    : ticket.price_applied === 'saarc'
                                        ? parseFloat(addonDetail.saarc_price)
                                        : parseFloat(addonDetail.international_price),
                            quantity: parseFloat(quantity),
                        }
                        : null;
                });

                const categoryPrice =
                    ticket.price_applied === 'nepali'
                        ? parseFloat(ticket.category_details.nepali_price)
                        : ticket.price_applied === 'saarc'
                            ? parseFloat(ticket.category_details.saarc_price)
                            : parseFloat(ticket.category_details.international_price);

                return {
                    baseTicketAmount: ticket.base_ticket_amount || "0.00",
                    billAmount: ticket.bill_amount || "0.00",
                    totalAddonsAmount: ticket.total_addons_amount || "0.00",
                    address: ticket.address || "",
                    dob: ticket.dob || "",
                    email: ticket.email || "",
                    organization: ticket.organization || "",
                    phoneNumber: ticket.phone_number || "",
                    zipCode: ticket.zip_code || "",
                    event_name: ticket.event_details?.name || "",
                    price_applied: ticket.price_applied || "",
                    categoryDetails: ticket.category_details.name || "",
                    first_name: ticket.first_name || "",
                    last_name: ticket.last_name || "",
                    category_price: isNaN(categoryPrice) ? "" : categoryPrice,
                    addons,
                };
            });

            setBillData(updatedBillData);
        }
    }, [invoice.tickets]);

    const handlePrint = () => {
        window.print(); // This will trigger the browser's print dialog
    };
    return (
        <>
            <style>
                {`
    @media print {
      .no-print {
        display: none;
      }
      @page {
        size: A4;
      }
    }
  `}
            </style>

            <div className="mx-auto no-print px-4 bg-[#1a202c] p-2 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                    <div className="flex items-center"></div>
                </div>
            </div>

            <div className="bg-gray-100 sm:py-10 print:py-4 font-custom">
                <div className="mx-auto relative max-w-4xl px-4 sm:px-6 rounded-lg lg:px-8 py-14 print:py-0 bg-white">
                    <div className="clearfix flex flex-col items-center w-full">
                        <div className="flex flex-wrap md:flex-nowrap flex-row gap-4 md:gap-8 pt-8 w-full justify-center md:justify-between items-center">
                            <div className="brightness-0">
                                <img src={images.HimalayanTrailRunningLogoVertical} className="h-28" alt="Company Logo" />
                            </div>
                            <div className="flex flex-row justify-between no-print py-8 gap-4 md:gap-8 no-print" >
                                {!invoice.is_paid &&
                                    <div className="flex flex-col gap-2">
                                        <button
                                            type='button'
                                            data-hs-overlay={`#hs-ai-invoice-modal-QR`}
                                            onClick={() => { handleFonePayPayment(invoice.uuid) }}
                                            className="bg-[#d11217] hover:bg-red-800  transition ease-in p-2 focus:ring focus:ring-red-600 text-white rounded-lg">
                                            Pay with FonePay
                                        </button>
                                        <img className="h-6 object-contain" src={images.fonePay} alt="" />
                                    </div>
                                }
                                {!invoice.is_paid &&
                                    <div className="flex flex-col gap-2">
                                        <button
                                            type='button'
                                            onClick={() => { handleBankTransfer(invoice.uuid) }}
                                            data-hs-overlay={`#hs-ai-invoice-modal-BankTransfer`}
                                            className="bg-gray-600 hover:bg-blue-800  transition ease-in p-2 focus:ring focus:ring-gray-600 text-white rounded-lg">
                                            Bank Transfer
                                        </button>
                                    </div>
                                }

                                {/* {!invoice.is_paid &&
                                    <div className="flex flex-col justify-between items-center gap-2">
                                        <button
                                            type="button"
                                            data-hs-overlay={`#hs-ai-invoice-modal-Stripe`}
                                            onClick={() => { handleStripePayment(invoice.uuid); }}
                                            className="bg-[#6860ff] hover:bg-violet-800  transition ease-in p-2 focus:ring focus:ring-violet-600 text-white rounded-lg">
                                            Pay with Stripe
                                        </button>
                                        <div className="p-CardBrandIcons p-CardBrandIcons--loaded p-CardBrandIcons--multiple flex flex-col gap-1"
                                            aria-describedby="cardBrandIconsDesc">
                                            <div className="flex flex-row gap-1">
                                                <div className="p-CardBrandIcons-item">
                                                    <img className="p-CardBrandIcon" alt="visa"
                                                        src="https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg" /></div>
                                                <div className="p-CardBrandIcons-item">
                                                    <img className="p-CardBrandIcon" alt="mastercard"
                                                        src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg" />
                                                </div>
                                                <div className="p-CardBrandIcons-item">
                                                    <img className="p-CardBrandIcon" alt="amex"
                                                        src="https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg" />
                                                </div>
                                                <img className="p-CardBrandIcon" alt="discover"
                                                    src="https://js.stripe.com/v3/fingerprinted/img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg" />
                                            </div>
                                            <div className="p-CardBrandIcons-item p-CardBrandIcons-more  flex flex-row gap-1">

                                                <img
                                                    className="p-CardBrandIcon" alt="diners"
                                                    src="https://js.stripe.com/v3/fingerprinted/img/diners-fbcbd3360f8e3f629cdaa80e93abdb8b.svg" />
                                                <img
                                                    className="p-CardBrandIcon" alt="jcb"
                                                    src="https://js.stripe.com/v3/fingerprinted/img/jcb-271fd06e6e7a2c52692ffa91a95fb64f.svg" />
                                                <img
                                                    className="p-CardBrandIcon" alt="unionpay"
                                                    src="https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg" />
                                                <img
                                                    className="p-CardBrandIcon p-CardBrandIcon--visible" alt="elo"
                                                    src="https://js.stripe.com/v3/fingerprinted/img/elo-efe873e884e6c9eb817f23a120caaa3e.svg" />
                                            </div>
                                        </div>
                                    </div>
                                } */}
                            </div>
                        </div>

                        <div className="flex flex-wrap flex-row gap-8 justify-between w-full text-sm py-4">
                            <div >
                                <div>Himalayan Trail Running Pvt. Ltd.</div>
                                <div>HallanChowk, Pokhara<br /> Gandaki, Nepal</div>
                                <div>(+977) 98460 31080</div>
                                <div><a href="mailto:himalayantrailrunning@gmail.com">himalayantrailrunning@gmail.com</a></div>
                            </div>
                            <div>
                                <div><span className="font-semibold">CUSTOMER</span> {invoice.user_details?.first_name} {invoice.user_details?.last_name}</div>
                                <div><span className="font-semibold">ADDRESS</span> {invoice.user_details?.address}</div>
                                <div><span className="font-semibold">EMAIL</span> <a href={`mailto:${invoice.user_details?.email}`}>{invoice.user_details?.email}</a></div>
                                <div><span className="font-semibold">DATE</span> {new Date(invoice.created_at).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', timeZone: 'Asia/Kathmandu' })} <p className="text-xs">Nepal Time</p></div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between">
                        <p className="text-sm"><strong>Invoice:</strong> {invoice.uuid}</p>
                        <p className="text-sm"><strong>Payment Status:</strong>  {invoice.is_paid === true ? (<>
                            <span className="hidden text-sm  print:inline-flex">Paid</span>
                            <span className="inline-flex no-print items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium text-green-800 bg-green-100">
                                <svg className="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                                Paid
                            </span>
                        </>
                        ) : (
                            <>
                                <span className="hidden  text-sm print:inline-flex">Unpaid</span>
                                <span className="inline-flex no-print items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium text-white bg-red-700">
                                    <svg className="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                    </svg>
                                    Unpaid
                                </span>
                            </>
                        )}
                        </p>
                    </div>
                    <main>
                        {billData.map((ticket, index) =>
                            <div key={index} className="w-full py-4 border p-4 m-4">
                                <div className="py-2">
                                    <p className="text-sm font-bold"> Ticket #{index + 1}</p>
                                    <p className="text-sm">Name: {ticket.first_name} {ticket.last_name}</p>
                                    <p className="text-sm">Address: {ticket.address}</p>
                                    <p className="text-sm">Phone Number: {ticket.phoneNumber}</p>
                                    <p className="text-sm">Date of Birth: {ticket.dob}</p>
                                </div>

                                <table className="text-sm divide-y w-full">
                                    <thead>
                                        <tr className="text-left text-sm">
                                            <th>#</th>
                                            <th>DESCRIPTION</th>
                                            <th>PRICE</th>
                                            <th>QTY</th>
                                            <th>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="py-2">1</td>
                                            <td className="py-2">{ticket.event_name} - {ticket.categoryDetails}</td>
                                            <td className="py-2"> Rs. {Number(ticket.category_price).toLocaleString('en-NP')}</td>
                                            <td className="py-2">1</td>
                                            <td className="py-2">Rs. {Number(ticket.category_price).toLocaleString('en-NP')}</td>
                                        </tr>
                                        {ticket.addons?.map((addons, index) =>
                                            <tr key={index}>
                                                <td className="py-2">{index + 2}</td>
                                                <td className="py-2">{addons.name}</td>
                                                <td className="py-2">Rs. {Number(addons.price).toLocaleString('en-NP')}</td>
                                                <td className="py-2">{addons.quantity}</td>
                                                <td className="py-2">Rs. {Number(addons.price * addons.quantity).toLocaleString('en-NP')}</td>
                                            </tr>
                                        )}
                                        <tr className="font-semibold">
                                            <td colspan="4" className="text-right py-2 pr-8">Total</td>
                                            <td>Rs. {Number(ticket.billAmount).toLocaleString('en-NP')}</td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        )}
                        <div className="p-4 m-4 w-full flex flex-col space-y-2">
                            {invoice.extra_charge > 0 &&
                                <div className="text-sm w-full flex flex-row justify-end space-x-8">
                                    <div className="text-right ">
                                        <p className="text-sm font-semibold">Extra Charge</p>
                                        <p className="text-xs">{invoice.extra_charge_remarks}</p>
                                    </div>
                                    <div className="text-left w-28">Rs. {Number(invoice.extra_charge).toLocaleString('en-NP')}</div>
                                </div>
                            }
                            {invoice.discount_amount > 0 &&
                                <div className="text-sm w-full flex flex-row justify-end space-x-8">
                                    <div className="text-right ">
                                        <p className="text-sm font-semibold">Discount Amount</p>
                                        <p className="text-xs">{invoice.discount_remarks}</p>
                                    </div>
                                    <div className="text-left w-28">Rs. {Number(invoice.discount_amount).toLocaleString('en-NP')}</div>
                                </div>
                            }
                            {invoice.tax_amount > 0 &&
                                <div className="text-sm w-full flex flex-row justify-end space-x-8">
                                    <div className="text-right ">
                                        <p className="text-sm font-semibold">Tax Amount</p>
                                        <p className="text-xs">{invoice.tax_remarks}</p>
                                    </div>
                                    <div className="text-left w-28">Rs. {Number(invoice.tax_amount).toLocaleString('en-NP')}</div>
                                </div>
                            }
                            <div className="text-sm w-full flex flex-row justify-end space-x-8">
                                <div className="text-right font-semibold">Grand Total</div>
                                <div className="text-left w-28">Rs. {Number(invoice.total_bill_amount).toLocaleString('en-NP')}</div>
                            </div>
                            <div className="text-sm w-full flex flex-row justify-end space-x-8">
                                <div className="text-right font-semibold">Amount Paid</div>
                                <div className="text-left w-28">Rs. {Number(invoice.paid_amount).toLocaleString('en-NP')}</div>
                            </div>

                        </div>

                    </main>
                    <div className="text-left">
                        <p>NOTICE:</p>
                        <p className="text-sm">The items sold are non-refundable and non-transferrable.</p>
                        <p className="text-sm">
                            Invoice was created on a computer and is valid without the signature and seal.
                        </p>
                    </div>
                    <div className="flex no-print justify-center pt-8">
                        <a type="button" onClick={handlePrint} class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm cursor-pointer" >
                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                                <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                            </svg>
                            Print
                        </a>
                    </div>
                </div>
            </div >

            <div id={`hs-ai-invoice-modal-Stripe`} className="hs-overlay overflow-scroll  hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto">
                <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0  ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
                    <div className="relative flex flex-col bg-white shadow-lg rounded-xl">
                        <div className="relative overflow-hidden min-h-[8rem]  text-center rounded-t-xl">
                            {/*<!-- Close Button --> */}
                            <div className="absolute top-2 right-2">
                                <button onClick={() => handleStripePaymentClose(invoice.uuid)} type="button" className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-900 transition-all text-sm " data-hs-overlay={`#hs-ai-invoice-modal-Stripe`} data-hs-remove-element={`#hs-ai-invoice-modal-Stripe`}>
                                    <span className="sr-only">Close</span>
                                    <svg className="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor" />
                                    </svg>
                                </button>
                            </div>

                            {stripeClientSecret && (
                                <Grid xs={8} md={8} item>
                                    <Elements options={options} stripe={stripePromise}>
                                        <StripePaymentComponent
                                            clientSecret={stripeClientSecret}
                                            // Pass the loading state and toggle function
                                            isSubmitting={invoice.uuid}
                                            invoiceUUID={invoice.uuid}
                                        />
                                    </Elements>
                                </Grid>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div id={`hs-ai-invoice-modal-QR`} className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto">
                <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0  ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
                    <div className="relative flex flex-col bg-white shadow-lg rounded-xl">
                        <div className="relative overflow-hidden min-h-[8rem]  text-center rounded-t-xl">
                            {/*<!-- Close Button --> */}
                            <div className="absolute top-2 right-2">
                                <button  type="button" className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-900 transition-all text-sm " data-hs-overlay={`#hs-ai-invoice-modal-QR`} data-hs-remove-element={`#hs-ai-invoice-modal-QR`}>
                                    <span className="sr-only">Close</span>
                                    <svg className="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor" />
                                    </svg>
                                </button>
                            </div>
                            {QRData &&
                                <div className='py-10 flex flex-col items-center'>
                                    <p className=' text-md py-4'>
                                        {msg}
                                    </p>
                                    <img src={`${QRData}`} alt='fonepay'
                                        style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                    <img className='py-4 h-20' src={images.fonePay} alt="" />
                                </div>}
                        </div>
                    </div>
                </div>
            </div>

            <div id={`hs-ai-invoice-modal-BankTransfer`} className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto">
                <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0  ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
                    <div className="relative flex flex-col bg-white shadow-lg rounded-xl">
                        <div className="relative overflow-hidden min-h-[8rem]  text-center rounded-t-xl">
                            {/*<!-- Close Button --> */}
                            <div className="absolute top-2 right-2">
                                <button type="button" className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-900 transition-all text-sm " data-hs-overlay={`#hs-ai-invoice-modal-BankTransfer`} data-hs-remove-element={`#hs-ai-invoice-modal-BankTransfer`}>
                                    <span className="sr-only">Close</span>
                                    <svg className="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor" />
                                    </svg>
                                </button>
                            </div>
                            <div className="p-10 ">
                                <h3 className="pb-4"><strong>Bank Transfer</strong></h3>
                                <p className="leading-5 pb-4 text-left">
                                    <strong>Bank:</strong> Himalayan Bank Limited <br />
                                    <strong>Address:</strong> New Road, Pokhara-9, Kaski, Gandaki, Nepal<br />
                                    <strong>Branch:</strong>POKHARA<br />
                                    <strong>Account Name:</strong> Himalayan Trail Running Pvt Ltd <br />
                                    <strong>Account No:</strong> 01608578720016 <br />
                                    <strong>SWIFT CODE:</strong> HIMANPKA <br />
                                </p>
                                <p className="leading-5 pb-6 text-left">
                                    Please, send the bank transfer invoice from the button below. And we'll approve and mark the payment as paid.
                                </p>
                                <a className="px-3 py-2 bg-gray-600 hover:bg-blue-800  transition ease-in p-2 focus:ring focus:ring-gray-600 text-white rounded-lg" href={`mailto:himalayantrailrunning@gmail.com?subject=Bank Transfer invoice of ${invoice.user_details?.first_name} ${invoice.user_details?.last_name}&body=I have made the payment for this invoice www.himalayantrailrunning.com/invoice/${invoice.uuid}. Please, confirm and mark it as paid. I've attached the receipt below.`}>Send Invoice</a>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Invoice;