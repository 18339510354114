import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import {
    PaymentElement
} from "@stripe/react-stripe-js";
import { useEffect } from "react";
import { Button } from '@mui/material';

export default function StripePaymentComponent({ clientSecret }) {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe, clientSecret]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: window.location.href,
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <form className='p-10 overflow-scroll' id="payment-form"
            onSubmit={handleSubmit}>
            <PaymentElement id="payment-element"
                options={paymentElementOptions} />
            <button disabled={isLoading || !stripe || !elements}
                id="submit">
                <Button id="button-text" variant="contained"  style={{marginTop: "40px"}}>
                    {isLoading ? <div className="spinner"
                        id="spinner"></div> : "Pay with Stripe"}
                </Button>
            </button>
        </form>
    );
}