import { useState } from 'react';
import SignIn from '../components/UserLogin/SignIn';
import SignUp from '../components/UserLogin/SignUp';
import RecoverAccount from '../components/UserLogin/RecoverAccount';

const UserLogin = () => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [showRecoverAccount, setShowRecoverAccount] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);

  const handleSignUpClick = () => {
    setShowSignUp(true);
    setShowRecoverAccount(false);
    setShowSignIn(false);
  };

  const handleSignInClick = () => {
    setShowSignIn(true);
    setShowRecoverAccount(false);
    setShowSignUp(false);
  }


  const handleForgotPasswordClick = () => {
    setShowSignIn(false);
    setShowRecoverAccount(true);
    setShowSignUp(false);
  };

  return (
    <>

      <div className="pt-20 font-custom">
        <div className="flex h-full items-center py-16">
          <main className="w-full max-w-md mx-auto p-6">
            <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm ">
              <div className="p-4 sm:p-7">

                {showSignIn &&
                  <div className="text-center">
                    <h1 className="block text-2xl font-bold text-gray-800 ">Log in</h1>
                    <p className="mt-2 text-sm text-gray-600 ">
                      Don't have an account yet?
                      <a className="text-blue-600 decoration-2 hover:underline font-medium pl-2 cursor-pointer" onClick={handleSignUpClick} >
                        Sign up here
                      </a>
                    </p>
                  </div>
                }

                {showSignUp &&
                  <div className="text-center">
                    <h1 className="block text-2xl font-bold text-gray-800 ">Sign Up</h1>
                    <p className="mt-2 text-sm text-gray-600 ">
                      Already have an account?
                      <a className="text-blue-600 decoration-2 hover:underline font-medium pl-2 cursor-pointer" onClick={handleSignInClick} >
                        Log in here
                      </a>
                    </p>
                  </div>
                }

                {showRecoverAccount &&
                  <div className="text-center">
                    <h1 className="block text-2xl font-bold text-gray-800 ">Forgot Password?</h1>
                    <p className="mt-2 text-sm text-gray-600 ">
                      Remember your password?
                      <a className="text-blue-600 decoration-2 hover:underline font-medium pl-2 cursor-pointer" onClick={handleSignInClick} >
                        Sign in here
                      </a>
                    </p>
                  </div>
                }

                <div className="mt-5">
                  {showSignIn && <SignIn handleForgotPasswordClick={handleForgotPasswordClick} />}
                  {showSignUp && <SignUp />}
                  {showRecoverAccount && <RecoverAccount />}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default UserLogin;