import react, { useEffect, useState } from 'react';
import { postApi } from '../../api';
import { useLocation, useNavigate } from 'react-router-dom';

const RecoverAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState({
        email: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [errors, setErrors] = useState({
        email: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = {};
        if (!formData.email) {
            newErrors.email = 'Email is required';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const response = await postApi('/users/password/forget/', {
                    email: formData.email,
                });

                if (response) {
                    navigate(`/password-reset?email=${formData.email}`)
                }

            } catch (error) {
                console.error('Sign-up failed', error);
            }
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid gap-y-4">
                <div>
                    <label className="block text-sm mb-2 ">Email address</label>
                    <div className="relative">
                        <input type="email"
                            id="email" name="email"
                            className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 "
                            onChange={handleChange}
                            value={formData.last_name}
                            required aria-describedby="email-error"
                        />
                        <div className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                            <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                            </svg>
                        </div>
                    </div>
                    {errors.email && <p className="text-xs text-red-600 mt-2">{errors.email}</p>}

                    <p className="hidden text-xs text-red-600 mt-2" id="email-error">Please include a valid email address so we can get back to you</p>
                </div>

                <button type="submit" className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-600 text-white hover:bg-red-700 ease-in duration-200 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 transition-all text-sm ">Reset password</button>
            </div>
        </form>
    )
}

export default RecoverAccount;