import Fishtail from '../assets/Fishtail.jpg';
import Sailung from '../assets/Sailung.jpg';
import Stupa from '../assets/Stupa.jpg';
import FishtailLogo from '../assets/FishtailLogo.png';
import GoldenTrailSeries from '../assets/golden-trail-series.png';
import Annapurna100 from '../assets/annapurna-100.png';
import Everest135 from '../assets/everest-135.png';
import FreedomAdventures from '../assets/freedom-adventures.png';
import GreatHimalRace from '../assets/great-himal-race.png';
import LeaveNoTrace from '../assets/leave-no-trace.png';
import PureTrails from '../assets/Pure-trails.png';
import RunnersCafe from '../assets/Runnerscafe.png';
import TrailRunningNepal from '../assets/trail-running-nepal.png';
import VonKathmandu from '../assets/von-kathmandu.png';
import HimalayanTrailRunningLogo from '../assets/himalayan-trail-running-logo.png';
import communitySupportImage from '../assets/community-support.jpg';
import HimalayanTrailRunningLogoVertical from '../assets/himalayan-trail-running-logo-vertical.png'
import tickList from '../assets/tick-list.svg'
import runner from '../assets/runner.svg'
import calendar from '../assets/calendar.svg'
import location from '../assets/location.svg'
import deadline from '../assets/deadline.svg'
import circle24 from '../assets/circle-24.png'
import ticket from '../assets/ticket.svg'
import HimalayanTrailRunningLogoOnly from '../assets/himalayan-trail-running-logo-vertical-notext.png'
import fourtytwoKMap from '../assets/42K-map.svg'
import fonePay from '../assets/fone-pay.png'
import fonePayDark  from '../assets/fone-pay-dark.png'
import itra from '../assets/itra.png'
import utmb from '../assets/utmb.png'

export default {
    Fishtail,
    Sailung,
    Stupa,
    FishtailLogo,
    GoldenTrailSeries,
    Annapurna100,
    Everest135,
    FreedomAdventures,
    GreatHimalRace,
    LeaveNoTrace,
    PureTrails,
    RunnersCafe,
    TrailRunningNepal,
    VonKathmandu,
    HimalayanTrailRunningLogo,
    communitySupportImage,
    HimalayanTrailRunningLogoVertical,
    tickList,
    runner,
    location,
    calendar,
    deadline,
    circle24,
    ticket,
    HimalayanTrailRunningLogoOnly,
    fourtytwoKMap,
    fonePay,
    fonePayDark,
    itra,
    utmb
};