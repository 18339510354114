import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { get } from '../api/index';
import { signOutAsync } from '../authRedux/store';
import Events from '../components/UserDashboard/Events';
import MainDashboard from '../components/UserDashboard/MainDashboard';
import UserProfile from '../components/UserDashboard/UserProfile';
import { images } from '../constants';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import convertToQueryParams from '../constants/convertToQueryParams';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const UserDashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userAuth = useSelector(state => state.auth.user);
    const [user, setUser] = useState({});
    const [events, setEvents] = useState([]);
    const [selectedComponent, setSelectedComponent] = useState('Dashboard');

    const [navigation, setNavigation] = useState([
        { name: 'Dashboard', current: true, type: 'header' },
        { name: 'Events', current: false, type: 'header' },
        { name: 'Your Profile', type: 'profile', current: false },
        { name: 'Sign out', type: 'profile', current: false },
    ]);

    const currentDate = new Date(); // Get the current date and time
    const isoDate = currentDate.toISOString();
    
    const [filters, setFilters] = useState({
      is_active: true,
      start_datetime_gte: isoDate,
      ordering: 'start_datetime',
      size: 10,
    })
  
    useEffect(() => {
      get(`/events/active/${convertToQueryParams(filters)}`)
        .then((res) => {
          if (res) {
            setEvents(res['results']);
          }
        })
        .catch((error) => {
          console.error('Error occurred while fetching data:', error);
        });
    }, [navigation]);

    useEffect(() => {
        const fetchData = async () => {
            if (userAuth) {
                try {
                    const res = await get(`/users/${userAuth.uuid}/`);
                    setUser(res);
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            }
        };

        fetchData();

    }, [userAuth]);

    const handleLogout = () => {
        dispatch(signOutAsync());
        navigate('/login');
    }

    const handleNavigationClick = (item) => {
        if (item.name === 'Sign out') {
            handleLogout();
            return;
        }

        // Set the selected component based on the clicked navigation item
        setSelectedComponent(item.name);

        // Update the navigation state to mark the clicked item as current
        const updatedNavigation = navigation.map((navItem) => {
            if (navItem.name === item.name) {
                return { ...navItem, current: true };
            } else {
                return { ...navItem, current: false };
            }
        });

        setNavigation(updatedNavigation);
    };

    return (
        <div className="min-h-full font-custom">
            <Disclosure as="nav" className="bg-gray-800">
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="flex h-16 items-center justify-between">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <a href="/">
                                            <img
                                                className="h-8 filter brightness-0 invert"
                                                src={images.HimalayanTrailRunningLogo}
                                                alt="Himalayan Trail Running"
                                            />
                                        </a>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-10 flex items-baseline space-x-4">
                                            {navigation.map((item) => (
                                                item.type === 'header' && (
                                                    <a
                                                        key={item.name}
                                                        onClick={() => handleNavigationClick(item)}
                                                        className={classNames(
                                                            item.current
                                                                ? 'bg-gray-900 text-white'
                                                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                            'rounded-md px-3 py-2 text-sm font-medium cursor-pointer'
                                                        )}
                                                        aria-current={item.current ? 'page' : undefined}
                                                    >
                                                        {item.name}
                                                    </a>
                                                )
                                            ))}

                                        </div>
                                    </div>
                                </div>
                                <div className="hidden md:block">
                                    <div className="ml-4 flex items-center md:ml-6">

                                        {/* Profile dropdown */}
                                        <Menu as="div" className="relative ml-3">
                                            <div>
                                                <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                    <span className="absolute -inset-1.5" />
                                                    <span className="sr-only">Open user menu</span>
                                                    {user.profile_image ? (
                                                        <img className='h-8 w-8 rounded-full object-cover' src={user.profile_image} />
                                                    ) : (
                                                        <AccountCircleIcon sx={{ height: 32, width: 32, color: 'white' }} />
                                                    )}
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    {navigation.map((item) => (
                                                        item.type === 'profile' && (
                                                            <Menu.Item key={item.name}>
                                                                {({ active }) => (
                                                                    <a
                                                                        onClick={() => handleNavigationClick(item)}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                                                        )}
                                                                    >
                                                                        {item.name}
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                        )
                                                    ))}

                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                                <div className="-mr-2 flex md:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="md:hidden">
                            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                {navigation.map((item) => (
                                    item.type !== 'profile' && (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            onClick={() => handleNavigationClick(item)}
                                            className={classNames(
                                                item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                'block rounded-md px-3 py-2 text-base font-medium'
                                            )}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </Disclosure.Button>)
                                ))}
                            </div>
                            <div className="border-t border-gray-700 pb-3 pt-4">
                                <div className="flex items-center px-5">
                                    <div className="flex-shrink-0">
                                        {user.profile_image ? (
                                            <img className='h-10 w-10 rounded-full object-cover' src={user.profile_image} />
                                        ) : (
                                            <AccountCircleIcon sx={{ height: 40, width: 40, color: 'white' }} />
                                        )}

                                    </div>
                                    <div className="ml-3">
                                        {user ? (
                                            <>
                                                <div className="text-base font-medium leading-none text-white">{user.first_name} {user.last_name}</div>
                                                <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                                            </>
                                        ) : (
                                            <div>Loading user information...</div>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-3 space-y-1 px-2">
                                    {navigation.map((item) => (
                                        item.type === 'profile' && (
                                            <Disclosure.Button
                                                key={item.name}
                                                as="a"
                                                onClick={() => handleNavigationClick(item)}
                                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        )
                                    ))}

                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            {selectedComponent === 'Dashboard' && <MainDashboard user={user} />}
            {selectedComponent === 'Events' && <Events events={events} />}
            {selectedComponent === 'Your Profile' && <UserProfile user={user} />}
        </div>
    )

}

export default UserDashboard;