import React from 'react';
import { images } from '../../../constants';

const EventHeader = ({ event }) => {
  return (
    <div className='overflow-hidden'>
      <img
        className={`absolute object-cover w-full md:w-full sm:w-full h-screen ken-burns`} // Add class for Ken Burns effect
        src={event.headline_image}
        alt=""
        style={{ transition: "left 2s" }}
      />
      <div className="absolute bottom-0 left-0 w-full h-screen bg-gradient-to-t from-black via-transparent "></div>
      <div className='max-w-7xl mx-auto p-10 pb-20 items-center w-full align-middle min-h-screen text-white '>
        <div className='absolute bottom-14 flex flex-col sm:flex-row items-center gap-8 '>
          {event.logo && (
            <div>
              <img className='object-contain  h-24' src={event.logo} alt={event.name} />
            </div>
          )}
          <div className='flex flex-col justify-between space-y-2'>
            <h1 className="font-black text-6xl uppercase leading-none break-normal">{event.name}</h1>

            <div className='col-span-1 row-span-1 flex flex-wrap flex-col sm:flex-row items-start sm:space-x-8 pl-1'>
              {event.start_datetime && (
                <div className='flex items-center space-x-2'>
                  <div>
                    <img className='object-fit w-4' src={images.calendar} alt="" />
                  </div>
                  <div>
                    <h5 className="text-lg">
                      {new Date(event.start_datetime).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', timeZone: 'Asia/Kathmandu' })}
                    </h5>
                  </div>
                </div>
              )}

              {event.category_details && event.category_details.length > 0 && (
                <div className='flex items-center space-x-2'>
                  <div>
                    <img className='object-fit w-4' src={images.runner} alt="" />
                  </div>
                  <div>
                    <h5 className="text-lg">
                      {event.category_details.reduce((acc, category, index) => {
                        if (index === 0) {
                          return category.name;
                        } else if (index === event.category_details.length - 1) {
                          return `${acc} & ${category.name}`;
                        } else {
                          return `${acc}, ${category.name}`;
                        }
                      }, '')}
                    </h5>
                  </div>
                </div>
              )}

              {event.general_area && (
                <div className='flex items-center space-x-2'>
                  <div>
                    <img className='object-fit w-4' src={images.location} alt="" />
                  </div>
                  <div>
                    <h5 className="text-lg">{event.general_area}</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventHeader;
