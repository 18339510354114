import React, { useEffect, useState } from 'react';
import { get } from '../../api/index';
import { images } from '../../constants';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import convertToQueryParams from '../../constants/convertToQueryParams';


import './Footer.css';

const companyLinks = [
  { title: 'Events', url: '/events' },
  { title: 'Blogs', url: '/blogs' },
  { title: 'About', url: '/about' },
];

const Footer = () => {
  const [events, setEvents] = useState([]);
  const currentDate = new Date(); // Get the current date and time
  const isoDate = currentDate.toISOString();

  const [filters, setFilters] = useState({
    is_active: true,
    start_datetime_gte: isoDate,
    ordering: 'start_datetime',
    size: 5,
  })

  useEffect(() => {
    get(`/events/active/${convertToQueryParams(filters)}`).then((res) => {
      if (res && res.results) { // Check if res.results is an array
        setEvents(res.results); // Assign the array of events to events
      }
    });

  }, [filters]);

  return (
    <>
      <div className='h-10 bg-[#2f353e] no-print text-white hidden lg:flex uppercase space-x-8 justify-center items-center font-black '>
        <h5>#HimalayanTrailRunning</h5>
        <h5>#Nepal</h5>
      </div>
      <footer className="bg-[#09090b] no-print text-white font-custom items-center mt-auto">
        <div className="max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 lg:pt-20 mx-auto">

          {/* <!-- Grid --> */}
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6">
            <div className="col-span-full lg:col-span-2  flex flex-row text-left md:gap-8">
              <a href='/' className="flex-none font-semibold  text-white" aria-label="Brand">
                <img src={images.HimalayanTrailRunningLogoVertical} className='filter brightness-0 invert w-0 md:w-40' alt="Himalayan Trail Running Logo" />
              </a>

              <div className='flex flex-col gap-2'>
                <div>
                  <h6 className="font-semibold text-gray-100">Office</h6>
                  <p className='leading-5 text-gray-400 break-all'>Himalayan Trail Running Pvt. Ltd.
                    <br />HallanChowk, Pokhara, Gandaki, Nepal
                    <br /><a className='hover:text-red-600 ease-in duration-200' href="tel:+9779846031080">(+977) 98460 31080</a>
                    <br /><a className='hover:text-red-600 ease-in duration-200' href="mailto:himalayantrailrunning@gmail.com">himalayantrailrunning@gmail.com</a></p>
                </div>
                <div className='flex flex-row gap-1 items-center'>
                  <a className="text-white hover:text-red-600 ease-in duration-200 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-gray-900 transition" href="https://www.facebook.com/himalayantrailrunning" target='_blank'>
                    <FacebookIcon sx={{ height: 24, }} />
                  </a>
                  <a className="text-white hover:text-red-600 ease-in duration-200 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-gray-900 transition" href="https://www.instagram.com/himalayan_trail_running/" target='_blank'>
                    <InstagramIcon sx={{ height: 24, }} />
                  </a>
                  <a className="text-white  ease-in flex items-center duration-200 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-gray-900 transition" href="https://twitter.com/TrailHimalayan" target='_blank'>
                    <svg className='h-5 fill-white hover:fill-red-600 duration-200' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                  </a>
                  <a aria-label="Chat on WhatsApp" href="https://wa.me/9779846031080" target='_blank' className="text-white hover:text-red-600 ease-in duration-200 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-gray-900 transition">
                    <WhatsAppIcon />
                  </a>
                </div>
              </div>

            </div>
            {/* <!-- End Col --> */}

            <div className="col-span-1">
              <h6 className="font-semibold text-gray-100">Events</h6>

              <div className="mt-1 grid space-y-1">
                {events.map((event, index) => (
                  <p key={index}>
                    <a className="inline-flex gap-x-2 text-gray-400 hover:text-red-600 ease-in duration-300" href={`/events/${event.slug}`}><small>{event.name}</small></a>
                  </p>
                ))}
              </div>
            </div>
            {/* <!-- End Col --> */}

            <div className="col-span-1">
              <h6 className="font-semibold text-gray-100">Company</h6>
              <div className="mt-1 grid space-y-1">
                {companyLinks.map((link, index) => (
                  <p key={`companyLinks_${index}`}>
                    <a className="inline-flex gap-x-2 text-gray-400  hover:text-red-600 ease-in duration-300" href={link.url}><small>{link.title}</small></a>
                  </p>
                ))}
              </div>
            </div>
            {/* <!-- End Col --> */}


            <div className="col-span-1 flex flex-col ">
              <h6 className="font-semibold text-gray-100">We accept</h6>
              <div className="w-full flex flex-col gap-2 items-start mt-1">
                <img className="h-6 object-contain" src={images.fonePayDark} alt="fone-pay" />
                <div className='flex flex-row gap-2'>

                  <img className="h-6 object-contain" alt="visa"
                    src="https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg" />
                  <img className="h-6 object-contain" alt="mastercard"
                    src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg" />
                  <img className="h-6 object-contain" alt="amex"
                    src="https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg" />
                  <img className="h-6 object-contain" alt="discover"
                    src="https://js.stripe.com/v3/fingerprinted/img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg" />
                </div>
                <div className='flex flex-row gap-2'>
                  <img className="h-6 object-contain"
                    src="https://js.stripe.com/v3/fingerprinted/img/diners-fbcbd3360f8e3f629cdaa80e93abdb8b.svg" />
                  <img className="h-6 object-contain"
                    src="https://js.stripe.com/v3/fingerprinted/img/jcb-271fd06e6e7a2c52692ffa91a95fb64f.svg" />
                  <img className="h-6 object-contain"
                    src="https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg" />
                  <img className="h-6 object-contain"
                    alt="elo"
                    src="https://js.stripe.com/v3/fingerprinted/img/elo-efe873e884e6c9eb817f23a120caaa3e.svg" />
                </div>
              </div>

            </div>
            {/* <!-- End Col --> */}
          </div>
          {/* <!-- End Grid --> */}

          <div className="mt-5 sm:mt-12 grid gap-y-2 sm:gap-y-0 sm:flex sm:justify-between sm:items-center">
            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-400">© 2023 <strong>Himalayan Trail Running</strong>. All rights reserved.</p>
            </div>
            {/* <!-- End Col --> */}


            {/* <!-- Social Brands --> */}
            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-400">Designed and Developed by <a className='hover:text-red-600 ease-in duration-200' href='https://www.himalayancreatives.com/' target='_blank'> <strong>Himalayan Creative Solution</strong></a></p>
            </div>
            {/* <!-- End Social Brands --> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
