import React, { useEffect, useState } from 'react';
import { get } from '../../../api/index';
import { useNavigate } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './Events.css';
import { motion } from 'framer-motion';
import convertToQueryParams from '../../../constants/convertToQueryParams';

const Events = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);

  const currentDate = new Date(); // Get the current date and time
  const isoDate = currentDate.toISOString();


  const [filters, setFilters] = useState({
    is_active: true,
    start_datetime_gte: isoDate,
    ordering: 'start_datetime',
    size: 3,
  })

  useEffect(() => {
    get(`/events/active/${convertToQueryParams(filters)}`)
      .then((res) => {
        if (res) {
          setEvents(res['results']);
        }
      })
      .catch((error) => {
        console.error('Error occurred while fetching data:', error);
      });
  }, []);

  window.onunhandledrejection = (event) => {
    console.error('Unhandled promise rejection:', event.reason);
  };

  const handleScroll = (scrollOffset) => {
    // Calculate the new scroll position and ensure it's within bounds
    const newScrollPosition = Math.max(0, Math.min(scrollPosition + scrollOffset, events.length - 1));
    setScrollPosition(newScrollPosition);
  };

  return (
    <div className='relative p-10 lg:px-20 lg:py-10 flex flex-col bg-gradient-to-b from-[#09090b] via-transparent to-[#09090b] bg-slate-800 text-white font-custom items-center'>
      <div className='absolute bottom-0 left-0 w-4/12 h-full lg:bg-gradient-to-r lg:from-[#09090b] lg:via-[#09090b] lg:z-20'></div>
      <div className='flex flex-wrap items-center justify-between max-w-[85rem] mx-auto md:pr-20'>
        <div className="w-full flex justify-center lg:w-4/12 lg:pr-10 lg:pb-20 z-20">
          <div className='text-center lg:text-left'>
            <h6 className='text-red-600'>EVENTS</h6>
            <div className='font-extrabold text-3xl'>Upcoming Events</div>
            <br />
            <p className='text-sm'>
              Join us for our upcoming trail running events, where you'll have the opportunity to conquer challenging routes surrounded by majestic mountains, lush forests, and cascading waterfalls. Our expertly curated courses cater to different skill levels, ensuring there's a race for everyone, from beginners to seasoned athletes.
            </p>
            <br />
            <a href='/events' className='mr-2 uppercase p-2 border-2 text-xs hover:bg-transparent hover:border-red-600  duration-300'>More Events</a>
          </div>
        </div>
        <div className="w-full lg:w-6/12 z-10">
          {/* carousel */}
          <motion.div className="carousel overflow-visible">
            <motion.div className="flex flex-wrap lg:flex-nowrap justify-center gap-8 py-10 snap-x snap-mandatory"
              style={{
                transform: `translateX(-${scrollPosition * 16.5}rem)`,
                transition: 'transform 0.5s ease', // Adding transition
              }}
              initial={false}>
              {events.map((event, index) => (
                <div key={index} onClick={() => { navigate(`/events/${event.slug}`) }}
                  className="h-[20rem] w-[15rem] bg-no-repeat bg-center bg-cover rounded-md"
                  style={{ backgroundImage: `url("${event.headline_image}")` }} // Adding a unique key for each element in the map loop
                >
                  <div className='text-center'>
                    <div className='h-[20rem] w-[15rem] bg-gradient-to-t from-black via-transparent rounded-md hover:rounded-md hover:shadow-lg ease-in cursor-pointer'>
                      <div className='h-[20rem] w-[15rem] grayscale hover:grayscale-0 flex text-white hover:text-red-600  justify-center items-center hover:transform hover:scale-[1.02]  hover:transition-all ease-in-out duration-300'>
                        <img src={event.logo} className='w-32 h-32 object-contain' alt={event.name} />
                        <div className='absolute bottom-5 text-center px-2'>
                          <p className='text-lg uppercase font-bold leading-tight'>{event.name}</p>
                          <p className='!text-white'>
                            {event.category_details && event.category_details.length > 0 && (
                              <p className='text-xs'>
                                {event.category_details.reduce((acc, category, index) => {
                                  if (index === 0) {
                                    return category.name;
                                  } else if (index === event.category_details.length - 1) {
                                    return `${acc} & ${category.name}`;
                                  } else {
                                    return `${acc}, ${category.name}`;
                                  }
                                }, '')}
                              </p>
                            )}
                          </p>
                        </div>
                        <div className='absolute top-5 left-6 text-left'>
                          <p className='text-xs !text-white'>{new Date(event.start_datetime).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', timeZone: 'Asia/Kathmandu' })}<br /></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              ))}
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Events;
