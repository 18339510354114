import react from 'react';

const EmailVerified = () => {

    return(
        <>
        </>
    )
}

export default EmailVerified;