import React, { useEffect, useState } from 'react';
import './NavigationBar.css';
import { images } from '../../constants';
import { useSelector } from 'react-redux'; // Assuming you are using Redux for state management
import { useNavigate, useLocation } from 'react-router-dom'; // Assuming you are using React Router for navigation

const links = [
  { label: 'Events', url: '/events' },
  { label: 'Blogs', url: '/blogs' },
  { label: 'About', url: '/about' },
];

function NavigationBar() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 50; // Adjust this value as needed

      setIsScrolled(scrollTop > scrollThreshold);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Check if the current location is one of the specified paths
  const isHomePage = location.pathname === '/';
  const isEventPage = location.pathname.startsWith('/events/'); // Check if it starts with '/event/'
  const isBlogPage = location.pathname.startsWith('/blogs/'); // Check if it starts with '/event/'

  // Determine the background color based on the current location
  let backgroundColor = '#1a202c'; // Default background color

  if (isHomePage || isEventPage || isBlogPage) {
    // Change background color when scrolling on the homepage or event pages
    backgroundColor = isScrolled ? '#1a202c' : 'transparent';
  }

  return (
    <div className={`flex flex-wrap fixed sm:justify-start sm:flex-nowrap z-50 w-full text-sm py-4 font-custom uppercase`}  style={{ backgroundColor }}>
      <nav className="mx-auto no-print max-w-7xl px-4 sm:px-6 lg:px-5 w-full sm:flex sm:items-center sm:justify-between" aria-label="Global" id="navbar">
        <div className="flex items-center justify-between">
          <a href="/">
            <img src={images.HimalayanTrailRunningLogo} alt="" className="w-40 filter brightness-0 invert" />
          </a>
          <div className="sm:hidden">
            <button
              type="button"
              className="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm "
              data-hs-collapse="#navbar-with-mega-menu"
              aria-controls="navbar-with-mega-menu"
              aria-label="Toggle navigation"
            >
              <svg className="hs-collapse-open:hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
              </svg>
              <svg className="hs-collapse-open:block hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </div>
        </div>
        <div id="navbar-with-mega-menu" className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block">
          <div className="flex flex-col gap-10 mt-5 sm:flex-row sm:items-center sm:justify-end sm:mt-0 sm:pl-5">
            {links.map((link, index) => (
              <a key={index} className="font-medium text-white hover:text-red-600 duration-300" href={link.url}>
                {link.label}
              </a>
            ))}
            {isAuthenticated ? (
              <a className="font-medium text-white hover:text-red-600 duration-300" href="/dashboard">
                Dashboard
              </a>
            ) : (
              <a className="font-medium text-white hover:text-red-600 duration-300" href="/login">
                Log In
              </a>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavigationBar;
