import Swal from "sweetalert2";


const ThrowAlert = Swal.mixin({
    toast: true,
    position: 'bottom-right',
    iconColor: 'white',
    customClass: {
        popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true
})

export default ThrowAlert;


// await Toast.fire({
//     icon: 'success',
//     title: 'Success'
//   })
//   await Toast.fire({
//     icon: 'error',
//     title: 'Error'
//   })
//   await Toast.fire({
//     icon: 'warning',
//     title: 'Warning'
//   })
//   await Toast.fire({
//     icon: 'info',
//     title: 'Info'
//   })
//   await Toast.fire({
//     icon: 'question',
//     title: 'Question'
//   })