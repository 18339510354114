import { useNavigate, useParams } from "react-router-dom";
import { get, postApi } from '../api/index';
import { React, useState, useEffect } from 'react';
import './RegistrationPage.css'
import AddonForm from '../components/RegistrationForm/AddonForm'
import EmergencyForm from '../components/RegistrationForm/EmergencyForm'
import PersonalInfoForm from '../components/RegistrationForm/PersonalInfoForm'
import Category from "../components/RegistrationForm/Category";
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

const RegistrationPage = () => {
  const navigate = useNavigate();
  const userAuth = useSelector(state => state.auth.user);
  const [priceApplied, setPriceApplied] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const { slug } = useParams();
  const [event, setEvent] = useState({});
  const [accordionActive, setAccordionActive] = useState(false);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    get(`/events/active/${slug}/`).then((res) => {
      setEvent(res);

      // Check if open_registration_form is false
      if (!res.is_registration_open) {
        // Redirect to /event/active/slug
        navigate(`/events/${slug}`);
      }
    });

    get(`/users/${userAuth.uuid}/`).then((res) => {
      setUser(res);
    });
  }, [slug, navigate, userAuth.uuid]);


  const [formData, setFormData] = useState({
    is_active: false,
    price_applied: '',
    addon_quantity: {},
    first_name: '',
    last_name: '',
    gender: '',
    email: '',
    dob: null,
    address: '',
    zip_code: '',
    city: '',
    country: '',
    phone_number: '',
    organization: '',
    blood_group: '',
    emergency_name: '',
    emergency_contact: '',
    relation: '',
    user_indexes: {},
    user_note: '',
    category: '',
    addons: [],
    size_preference: '',
  });

  useEffect(() => {
    if (user && event && event.accepted_indexes) {
      const acceptedIndexes = event.accepted_indexes;
      const userIndexes = user.indexes || {};
      const matchedIndexes = {};

      if (Array.isArray(acceptedIndexes)) {
        acceptedIndexes.forEach(indexName => {
          if (userIndexes.hasOwnProperty(indexName)) {
            matchedIndexes[indexName] = userIndexes[indexName];
          }
        });
      } else {
        console.error("acceptedIndexes is not an array.");
      }

      let priceApplied = 'International'; // Default value
      setPriceApplied("International");

      if (user.country === 'Nepal') {
        priceApplied = 'Nepali';
        setPriceApplied("Nepali")
      } else if (['Afghanistan', 'Bangladesh', 'Bhutan', 'India', 'Maldives', 'Pakistan', 'Sri Lanka'].includes(user.country)) {
        priceApplied = 'SAARC';
        setPriceApplied("SAARC")
      }

      setFormData(prevFormData => ({
        ...prevFormData,
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        email: user.email || '',
        gender: user.gender || '',
        address: user.address || '',
        zip_code: user.zip_code || '',
        city: user.city || '',
        country: user.country || '',
        phone_number: user.phone_number || '',
        blood_group: user.blood_group || '',
        user_indexes: matchedIndexes,
        price_applied: priceApplied,
        dob: user.dob ? new Date(user.dob) : null,
        indexes: user?.indexes || {},
        size_preference: user?.preferred_size || '',
      }));
    }
  }, [user, event]);

  const [addons, setAddons] = useState({});
  const handleCountryChange = (event) => {
    const selectedCountryValue = event.target.value;
    setSelectedCountry(event.target.value);

    let updatedPriceApplied = '';

    if (selectedCountryValue === 'Nepal') {
      updatedPriceApplied = 'Nepali';
    } else if (['Afghanistan', 'Bangladesh', 'Bhutan', 'India', 'Maldives', 'Pakistan', 'Sri Lanka'].includes(selectedCountryValue)) {
      updatedPriceApplied = 'SAARC';
    } else {
      updatedPriceApplied = 'International';
    }

    // Reset addons and addon_quantity here
    setFormData((prevData) => ({
      ...prevData,
      price_applied: updatedPriceApplied,
      addons: [], // Reset addons array to an empty array
      addon_quantity: {}, // Reset addon_quantity object to an empty object
      addon_order: [],
    }));

    setPriceApplied(updatedPriceApplied);
    setAddons([])
    setBillData([])
  };

  const handleSubmit = () => {
    setIsLoading(true); // Start loading
    let data = { ...formData, dob: format(formData.dob, 'yyyy-MM-dd') };
    postApi('/sales/tickets/', data)
      .then(response => {
        navigate(`/invoice/${response.uuid}`);
        // Handle any additional success actions
      })
      .catch(error => {
        console.error('Error posting form data:', error);
        // Handle error or show error feedback
      })
      .finally(() => {
        setIsLoading(false); // End loading
      });
  };

  const handleDateChange = (name, date) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: date
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const [fieldName, index] = name.split('.');

    if (fieldName === 'user_indexes' && index !== undefined) {
      setFormData((prevData) => ({
        ...prevData,
        user_indexes: {
          ...prevData.user_indexes,
          [index]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const stepIndicatorData = [
    { text: "Personal information" },
    { text: "Emergency Information" },
    { text: "Distance, Add-ons and Payments" },
  ];

  const stepIndicator = (
    <div className="text-2xl font-bold flex flex-row flex-wrap w-full text-center">
      {stepIndicatorData?.map((data, index) => (
        <div className="basis-1/3" key={index}>
          <span className={`mr-4 ${step === index + 1 ? "text-red-600" : "text-gray-400"}`}>
            {index + 1}<br />
            <p className='text-xs'>{data.text}</p>
          </span>
        </div>
      ))}
    </div>
  );

  const [billData, setBillData] = useState([]);

  useEffect(() => {
    const updatedBillData = {
      categories: formData.category
        ? event?.category_details
          .filter((category) => category?.uuid === formData.category)
          .map((category) => ({
            uuid: category?.uuid,
            name: category?.name,
            price:
              formData.price_applied === 'Nepali'
                ? parseFloat(category.nepali_price)
                : formData.price_applied === 'SAARC'
                  ? parseFloat(category.saarc_price)
                  : parseFloat(category.international_price),
          }))
        : [],
      addons: formData.addons.map((addonId) => {
        const addonDetail = event.addon_details.find(
          (addon) => addon.id === addonId
        );
        const quantity = formData?.addon_quantity[addonId] || 0;
        return addonDetail
          ? {
            id: addonDetail.id,
            name: addonDetail.name,
            price:
              formData.price_applied === 'Nepali'
                ? parseFloat(addonDetail.nepali_price)
                : formData.price_applied === 'SAARC'
                  ? parseFloat(addonDetail.saarc_price)
                  : parseFloat(addonDetail.international_price),
            quantity: parseFloat(quantity),
          }
          : null;
      }),
    };

    let totalCost = 0.00; // Initialize as a number with two decimal places

    // Add category price to total cost
    if (updatedBillData.categories.length > 0) {
      totalCost += updatedBillData.categories[0].price;
    }

    // Add addon prices * quantity to total cost
    updatedBillData.addons.forEach((addon) => {
      totalCost += addon.price * addon.quantity; // Ensure both are treated as numbers
    });

    // Format totalCost as a string with two decimal places
    updatedBillData.totalCost = totalCost.toFixed(2);

    setBillData(updatedBillData);
  }, [event.category_details, event.addon_details, formData.category, formData.addons, formData.addon_quantity, formData.price_applied]);

  useEffect(() => {
    const initialAddons = []; // Replace with your initial addons data
    const initialAddonQuantity = {}; // Replace with your initial addon_quantity data
    setFormData((prevData) => ({
      ...prevData,
      addons: initialAddons,
      addon_quantity: initialAddonQuantity,
    }));
  }, [selectedCountry]);

  const isValid = (formData) => {
    // Check if any of the required fields are empty
    if (
      formData.category.trim() === ''
    ) {
      return false;
    }
    // If all required fields are non-empty, return true
    return true;
  };

  const handleClickToClear = () => {
    // Define your initial state for formData here
    const initialFormData = {
      is_active: false,
      price_applied: '',
      addon_quantity: {},
      first_name: '',
      last_name: '',
      gender: '',
      email: '',
      dob: null,
      address: '',
      zip_code: '',
      city: '',
      country: '',
      phone_number: '',
      organization: '',
      blood_group: '',
      emergency_name: '',
      emergency_contact: '',
      relation: '',
      user_indexes: {},
      user_note: '',
      category: '',
      addons: [],
      size_preference: '',
    };

    // Reset the formData state to its initial state
    setFormData(initialFormData);
  };

  return (
    <div className="h-auto font-custom ">
      <div className="pt-20 w-full bg-slate-900">
        <div className='max-w-[85rem] mx-auto'>
          {stepIndicator}
        </div>
      </div>

      <div className="px-10 md:px-20 my-20 max-w-[85rem] relative mx-auto">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-9/12 lg:pr-20">
            {step === 1 && <PersonalInfoForm user={user} handleClickToClear={handleClickToClear} handleDateChange={handleDateChange} nextStep={nextStep} event={event} formData={formData} handleChange={handleChange} handleCountryChange={handleCountryChange} />}
            {step === 2 && <EmergencyForm user={user} nextStep={nextStep} previousStep={previousStep} event={event} formData={formData} handleChange={handleChange} />}
            {step === 3 && <>
              <Category previousStep={previousStep} user={user} formData={formData} event={event} handleChange={handleChange} priceApplied={priceApplied} />
              <AddonForm previousStep={previousStep} user={user} formData={formData} addons={addons} setAddons={setAddons} event={event} handleChange={handleChange} priceApplied={priceApplied} />
            </>}
          </div>

          <div className="hidden lg:block lg:w-3/12 ">
            <div className='border-2 p-8 sticky top-32 '>
              <div className="">
                <div>
                  <small>Registration for</small>
                  <h5 className='font-bold'>{event.name}</h5>
                  <small>{new Date(event.start_datetime).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', timeZone: 'Asia/Kathmandu' })}</small>
                </div>

                {
                  formData.first_name && formData.last_name && formData.country &&
                  <div className="mt-4">
                    <h6 className="font-semibold uppercase pb-4">Order Summary</h6>
                    <div className="flex justify-between items-center">
                      <p className="text-sm">{formData.first_name} {formData.last_name}</p>
                      {/* <img
                        src={getFlagUrlByCountryName(formData.country)}
                        alt={`${formData.country} Flag`}
                        className="w-6" // Set the appropriate width and height for the flag
                      /> */}
                    </div>
                    <hr />
                  </div>
                }

                {billData.totalCost > 0 && (
                  <>
                    {billData.categories?.map((category) => (
                      <div className="flex w-full py-1 justify-between" key={category.uuid}>
                        <p className="text-sm w-2/3">{category.name}</p>
                        <p className="text-sm w-1/3 text-right">Rs. {Number(category.price).toLocaleString('en-NP')}</p>
                      </div>
                    ))}

                    {billData.addons?.map((addon) => (
                      <div className="flex items-center w-full py-1 justify-between" key={addon.id}>
                        <p className="text-sm w-2/3">{addon.name} x {addon.quantity}</p>
                        <p className="text-sm w-1/3 text-right">Rs. {Number(addon.quantity * addon.price).toLocaleString('en-NP')}</p>
                      </div>
                    ))}

                    <div className="pt-2">
                      <hr />
                      <div className='pt-2 text-sm font-semibold flex flex-wrap justify-between'>
                        <span>Total:</span>
                        <span>Rs. {Number(billData.totalCost).toLocaleString('en-NP')}</span>
                      </div>
                    </div>
                  </>
                )}

              </div>
              <button
                className={`w-full text-center p-3 rounded-md mt-4 uppercase ${isLoading
                  ? "bg-gray-200 text-gray-400" // Grey background and white text when loading
                  : isValid(formData)
                    ? "bg-red-600 text-white hover:bg-red-700 ease-in duration-200"
                    : "bg-gray-200 text-gray-400"
                  }`}
                onClick={handleSubmit}
                disabled={!isValid(formData) || isLoading} // Disable when loading
              >
                {isLoading ? "Registering..." : "Register"}
              </button>


            </div>
          </div>
        </div>


      </div>
      <footer className="block lg:hidden z-50 sticky bottom-0 mt-auto py-3 bg-gray-800 visible md:ini">
        <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 justify-between items-center text-white">

          <>
            <div className="hs-accordion-group">
              <div className="hs-accordion" id="hs-basic-with-arrow-heading-one">
                <button
                  className={`hs-accordion-toggle ${accordionActive ? 'hs-accordion-active:text-red-600 group' : ''
                    } py-3 inline-flex items-center gap-x-3 w-full font-semibold text-left  transition  hs-accordion-active:text-white text-gray-200 hover:text-gray-500`}
                  aria-controls="hs-basic-with-arrow-collapse-one"
                  onClick={() => setAccordionActive(!accordionActive)} // Toggle accordion state
                ><svg className="hs-accordion-active:hidden hs-accordion-active:text-red-600 hs-accordion-active:group-hover:text-red-600 block w-3 h-3  group-hover:text-gray-500 text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                  </svg>
                  <svg className="hs-accordion-active:block hs-accordion-active:text-red-600 hs-accordion-active:group-hover:text-red-600 hidden w-3 h-3  group-hover:text-gray-500 text-gray-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                  </svg>
                  Order Summary
                </button>
                <div
                  id="hs-basic-with-arrow-collapse-one"
                  className={`hs-accordion-content w-full overflow-hidden transition-[height] duration-300 ${accordionActive ? 'block' : 'hidden'
                    }`}
                  aria-labelledby="hs-basic-with-arrow-heading-one"
                ><div className="flex flex-col py-4">
                    <div>
                      <small>Registration for</small>
                      <h5 className='font-bold'>{event.name}</h5>
                      <small>{new Date(event.start_datetime).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', timeZone: 'Asia/Kathmandu' })}</small>
                    </div>

                    {
                      formData.first_name && formData.last_name && formData.country &&
                      <div className="mt-4">
                        <h6 className="font-semibold uppercase pb-4">Order Summary</h6>
                        <div className="flex justify-between items-center">
                          <p className="text-sm">{formData.first_name} {formData.last_name}</p>
                          {/* <img
                            src={process.env.PUBLIC_URL + `${getFlagUrlByCountryName(formData.country)}`}
                            alt={`${formData.country} Flag`}
                            className="w-6" // Set the appropriate width and height for the flag
                          /> */}
                        </div>
                        <hr />
                      </div>
                    }

                    {billData.totalCost > 0 && (
                      <>
                        {billData.categories?.map((category) => (
                          <div className="flex w-full py-1 justify-between" key={category.uuid}>
                            <p className="text-sm">{category.name}</p>
                            <p className="text-sm">Rs. {Number(category.price).toLocaleString('en-NP')}</p>
                          </div>
                        ))}

                        {billData.addons?.map((addon) => (
                          <div className="flex w-full py-1 justify-between" key={addon.id}>
                            <p className="text-sm">{addon.name} x {addon.quantity}</p>
                            <p className="text-sm">Rs. {Number(addon.quantity * addon.price).toLocaleString('en-NP')}</p>
                          </div>
                        ))}

                        <div className="pt-2">
                          <hr />
                          <div className='pt-2 text-sm font-semibold flex flex-wrap justify-between'>
                            <span>Total:</span>
                            <span>Rs. {Number(billData.totalCost).toLocaleString('en-NP')}</span>
                          </div>
                        </div>
                      </>
                    )}

                  </div>
                </div>
              </div>
            </div>

            <button
              className={`w-full text-center p-3 rounded-md mt-4 uppercase ${isLoading
                ? "bg-gray-200 text-gray-400" // Grey background and white text when loading
                : isValid(formData)
                  ? "bg-red-600 text-white hover:bg-red-700 ease-in duration-200"
                  : "bg-gray-200 text-gray-400"
                }`}
              onClick={handleSubmit}
              disabled={!isValid(formData) || isLoading} // Disable when loading
            >
              {isLoading ? "Registering..." : "Register"}
            </button>
          </>
        </div>
      </footer>
    </div >
  );
}

export default RegistrationPage;