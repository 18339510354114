import { React, useEffect, useState } from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './Testimonials.css';
import { get } from '../../../api/index';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  useEffect(() => {
    get('/content/testimonials/')
      .then((res) => {
        if (res) {
          // Reverse the order of testimonials
          const reversedTestimonials = res['results'].reverse();
          setTestimonials(reversedTestimonials);
        }
      })
      .catch((error) => {
        console.error('Error occurred while fetching data:', error);
      });
  }, []);

  if (testimonials.length === 0) {
    return null; // or you can return a loading spinner or a message
  }

  return (
    <div className='relative overflow-hidden bg-[#09090b] pt-24 pb-16 text-white font-custom items-center '>
      <div className='max-w-[85rem] mx-auto'>
        <h6 className='text-center text-red-600'>TESTIMONIALS</h6>
        <h2 className="text-3xl font-semibold text-center text-white">What Our Clients say</h2>
        <p className="mt-2 text-gray-500 text-center text-sm  px-10 lg:w-4/5 mx-auto">We specialize in organizing and managing trail running races while also providing comprehensive training programs for volunteers and runners. With our expertise, we create unforgettable race experiences and empower individuals to contribute effectively to the success of our events.</p>
        <CarouselProvider
          naturalSlideHeight={50}
          naturalSlideWidth={300}
          totalSlides={testimonials.length}
          visibleSlides={1}
          infinite={1}
          isPlaying={1}
          isIntrinsicHeight={1}
        >
          <div className='relative'>
            <ButtonBack className={`absolute top-[40%] hidden md:block md:left-48 z-20 text-gray-400 hover:text-red-600 ease-in duration-200`} ><ArrowBackIosIcon/></ButtonBack>

            <Slider className='py-0'>
              {testimonials.map((testimonial, index) => (
                <Slide index={index} key={`test_${index}`}>
                  <div key={`test2_${index}`} className="relative max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto w-4/5 lg:mx-auto lg:w-3/5 cursor-grab">
                    <blockquote className="text-center ">
                      <div className="mt-6 lg:mt-10">
                        <p className="relative text-xl sm:text-2xl md:text-3xl md:leading-normal font-medium text-gray-800">
                          <svg className="absolute top-0 left-0 transform -translate-x-16 -translate-y-8 h-16 w-16 sm:h-24 sm:w-24 text-[#2f353e]" width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z" fill="currentColor" />
                          </svg>
                          <p className="relative z-10 italic text-gray-200 text-[1.3rem] leading-7">
                            {testimonial.content}
                          </p>
                        </p>
                      </div>

                      <footer className="flex flex-col items-center justify-between mt-4">
                        <img className='h-16 w-16 object-top object-cover rounded-full m-2' src={testimonial.logo} alt="" />
                        <div className="font-semibold text-gray-200 leading-6">{testimonial.name}</div>
                        <div className="text-sm text-gray-500">{testimonial.designation} | {testimonial.company}</div>

                      </footer>

                    </blockquote>
                  </div>
                </Slide>
              ))}
            </Slider>

            <ButtonNext className='absolute top-[40%] hidden md:block md:right-48 z-20 text-gray-400  hover:text-red-600 ease-in duration-200'><ArrowForwardIosIcon/></ButtonNext>
          </div>
        </CarouselProvider>
      </div>
    </div>

  )
}

export default Testimonials;
