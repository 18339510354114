import React, { useEffect, useState } from 'react';
import { get } from '../api/index';
import convertToQueryParams from '../constants/convertToQueryParams';
import { Disclosure } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'

const Event = () => {
  const [coreEvents, setCoreEvents] = useState({});
  const [events, setEvents] = useState([]);
  const [eventTags, setEventTags] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [currentOrdering, setCurrentOrdering] = useState(null);
  const [selectedEventTags, setSelectedEventTags] = useState([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [selectedLi, setSelectedLi] = useState('allevents');
  const clearSelectedLi = () => {
    setSelectedLi(null);
  };

  const currentDate = new Date(); // Get the current date and time
  const isoDate = currentDate.toISOString();

  const [filters, setFilters] = useState({
    is_active: true,
    ordering: 'start_datetime',
    page: currentPage,
    search: null,
    size: 9,
    start_datetime_gte: null,
    start_datetime_lte: null,
    end_datetime_gte: null,
    end_datetime_lte: null,
    event_tags: null,
  })

  useEffect(() => {
    get(`/events/active/${convertToQueryParams(filters)}`).then((res) => {
      if (res && res.results) { // Check if res.results is an array
        setEvents(res.results); // Assign the array of events to events
      }
    });

  }, [filters]);

  useEffect(() => {
    Promise.all([
      get('/events/tags/'),
      get('/events/types/')
    ])
      .then(([tagsRes, typesRes]) => {
        if (tagsRes) {
          setEventTags(tagsRes['results']);
        }
        if (typesRes) {
          setEventTypes(typesRes['results']);
        }
      })
      .catch((error) => {
        console.error('Error occurred while fetching data:', error);
      });
  }, []);

  useEffect(() => {
    const updatedFilters = {
      ...filters,
      event_tags: selectedEventTags.length ? selectedEventTags.join(',') : null,
      event_type: selectedEventTypes.length ? selectedEventTypes.join(',') : null,
      page: currentPage + 1,
    };

    setFilters(updatedFilters);
  }, [selectedEventTags, selectedEventTypes, currentPage]);

  const handleSubCategoryClick = (categoryName) => {
    if (categoryName === 'upcoming') {
      // Set the start_datetime_gte filter to isoDate for upcoming events
      setFilters({ ...filters, start_datetime_gte: isoDate, end_datetime_lte: null });
    } else if (categoryName === 'past') {
      // Set the end_datetime_lte filter to isoDate for past events
      setFilters({ ...filters, start_datetime_gte: null, end_datetime_lte: isoDate });
    } else if (categoryName === 'allevents') {
      // Reset all filters
      setFilters({
        ...filters,
        is_active: true,
        ordering: 'start_datetime',
        page: currentPage,
        search: null,
        size: 6,
        start_datetime_gte: null,
        start_datetime_lte: null,
        end_datetime_gte: null,
        end_datetime_lte: null,
        event_tags: null,
      });
      setSelectedEventTags([]);
      setSelectedEventTypes([]);
    }
  };

  const handleTagCheckboxChange = (value, isChecked) => {
    // Create a copy of the selectedEventTags array
    const updatedSelectedEventTags = [...selectedEventTags];

    if (isChecked) {
      // If the checkbox is checked, add the value to the array
      updatedSelectedEventTags.push(value);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      const index = updatedSelectedEventTags.indexOf(value);
      if (index !== -1) {
        updatedSelectedEventTags.splice(index, 1);
      }
    }

    // Update the selectedEventTags state with the new array
    setSelectedEventTags(updatedSelectedEventTags);
  };

  function handleTypeRadioChange(optionValue, isChecked) {
    if (isChecked) {
      setSelectedEventTypes([optionValue]);
    } else {
      setSelectedEventTypes(selectedEventTypes.filter((value) => value !== optionValue));
    }
  }

  const totalPages = Math.ceil(coreEvents.length / filters.size);
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  const filterVar = [
    {
      id: 'eventTypes',
      name: 'Event Types',
      options: eventTypes.map(type => ({
        value: type.id, // Assuming each event type has an "id" property
        label: type.name, // Assuming each event type has a "name" property
        checked: false, // You can set this based on your logic
      })),
    },
    {
      id: 'eventTags',
      name: 'Event Tags',
      options: eventTags.map(tag => ({
        value: tag.id, // Assuming each tag has an "id" property
        label: tag.name, // Assuming each tag has a "name" property
        checked: false, // You can set this based on your logic
      })),
    },
  ];

  return (
    <>
      <div className="pt-20 font-custom">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl md:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Events</h2>
            <p className="mt-2 text-sm text-gray-600">
              Explore Exciting Trail Running Events: Dive into the world of trail running with our handpicked events. From races to workshops, find your next adventure here.
            </p>
          </div>
        </div>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-wrap flex-col space-y-8  '>
          <section aria-labelledby="products-heading" className="pb-24 ">

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <form>
                <ul role="list" className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900 ">
                  <li key="allevents" className={selectedLi === 'allevents' ? 'text-red-600' : 'hover:text-red-600 ease-in duration-200'}>
                    <button
                      type='button'
                      onClick={() => handleSubCategoryClick('allevents')}
                      className={currentOrdering === 'allevents' ? 'text-blue-500' : ''}
                      onMouseDown={() => setSelectedLi('allevents')} // Add this line
                    >
                      All Events
                    </button>
                  </li>
                  <li key="upcoming" className={selectedLi === 'upcoming' ? 'text-red-600' : 'hover:text-red-600 ease-in duration-200'}>
                    <button
                      type='button'
                      onClick={() => handleSubCategoryClick('upcoming')}
                      className={currentOrdering === 'upcoming' ? 'text-blue-500' : ''}
                      onMouseDown={() => setSelectedLi('upcoming')} // Add this line
                    >
                      Upcoming Events
                    </button>
                  </li>
                  <li key="past" className={selectedLi === 'past' ? 'text-red-600' : 'hover:text-red-600 ease-in duration-300'}>
                    <button
                      type='button'
                      onClick={() => handleSubCategoryClick('past')}
                      className={currentOrdering === 'past' ? 'text-blue-500' : ''}
                      onMouseDown={() => setSelectedLi('past')} // Add this line
                    >
                      Past Events
                    </button>
                  </li>

                </ul>



                {filterVar.map((section) => (
                  <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6 ">
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root ">
                          <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-red-600 ease-in">
                            <span className="font-medium text-gray-900">{section.name}</span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon className="h-5 w-5" aria-hidden="true" />
                              ) : (
                                <PlusIcon className="h-5 w-5" aria-hidden="true" />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            {section.options.map((option, optionIdx) => (
                              <div key={option.value} className="flex items-center">
                                {section.id === 'eventTags' ? (
                                  <input
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    id={`filter-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    defaultValue={option.value}
                                    type="checkbox"
                                    checked={
                                      selectedEventTags.includes(option.value)
                                    }
                                    onChange={(e) =>
                                      handleTagCheckboxChange(option.value, e.target.checked)
                                    }

                                  />) : (
                                  <input
                                    className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-indigo-500"
                                    id={`filter-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    defaultValue={option.value}
                                    type="radio"
                                    checked={
                                      selectedEventTypes.includes(option.value)
                                    }
                                    onChange={(e) =>
                                      handleTypeRadioChange(option.value, e.target.checked)
                                    }

                                  />
                                )}

                                <label htmlFor={`filter-${section.id}-${optionIdx}`} className="ml-3 text-sm text-gray-600">
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form>

              {/* Product grid */}
              <div className="lg:col-span-3">
                <div className="grid grid-cols-2 gap-x-6 gap-y-10 md:grid-cols-3 lg:grid-cols-3 xl:gap-x-8">
                  {events.map((event, index) => (
                    <div key={index} className="group relative">
                      <a
                        type='button'
                        href={`events/${event.slug}`}
                        className={`w-full rounded-md h-60 bg-no-repeat bg-center bg-cover text-gray-900 hover:text-red-600 ease-in duration-200 cursor-pointer relative`}
                        style={{
                          backgroundImage: `url("${event.headline_image}")`,
                          // filter: new Date(event.start_datetime) < currentDate ? 'grayscale(100%)' : 'none'
                        }}
                      >
                        <div className='absolute top-3 right-3 flex flex-row gap-2'>
                          <button className='rounded-xl bg-gray-600 py-1 px-2 text-white text-xs'>
                            {event.event_type_detail?.name}
                          </button>


                        </div>
                        <div className='w-full h-full bg-gradient-to-t from-black via-transparent group-hover:opacity-75 transition rounded-md hover:rounded-md hover:shadow-lg ease-in flex items-center justify-center'>
                          <img src={event.logo} className='w-32 h-32 object-contain' alt={event.name} />
                        </div>
                        <div className='flex flex-row justify-between items-center'>
                          <p className='text-lg font-bold leading-none uppercase py-3'>{event.name}</p>
                          <div >
                            {new Date(event.start_datetime) < currentDate ? (
                              <button className='rounded-xl bg-gray-600 py-1 px-2 text-white text-xs'>
                                Ended
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </a>
                      <p className=" text-xs text-gray-500">{event.general_area}</p>

                      <div className="flex flex-nowrap justify-between items-end">
                        <div className='basis-2/3'>

                          <p className='text-sm font-medium text-gray-900'>
                            {event?.category_details?.reduce((acc, category, index) => {
                              if (index === 0) {
                                return category.name;
                              } else if (index === event.category_details?.length - 1) {
                                return `${acc} & ${category.name}`;
                              } else {
                                return `${acc}, ${category.name}`;
                              }
                            }, '')}
                          </p>
                        </div>
                        <div className='basis-1/3'>
                          <p className="text-sm font-medium text-gray-900">{new Date(event.start_datetime).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', timeZone: 'Asia/Kathmandu' })}</p>
                        </div>
                      </div>

                    </div>
                  ))}
                </div>
                {pageNumbers > 0 &&
                  <div className=" w-full flex justify-between text-center mt-4">
                    <nav className="flex items-center space-x-2">
                      <a
                        className="text-gray-500 hover:text-blue-600 p-4 inline-flex items-center gap-2 rounded-md"
                        href="#"
                      >
                        <span aria-hidden="true" onClick={() => setCurrentPage(currentPage - 1)}>«</span>
                        <span className="sr-only">Previous</span>
                      </a>
                      {pageNumbers.map((pageNumber) => (
                        <a
                          key={pageNumber}
                          className={`w-10 h-10 text-gray-500 hover:text-blue-600 p-4 inline-flex items-center text-sm font-medium rounded-full ${pageNumber === currentPage ? 'text-blue-600' : ''
                            }`}
                          href={`#${pageNumber}`}
                        >
                          {pageNumber}
                        </a>
                      ))}
                      <a
                        className="text-gray-500 hover:text-blue-600 p-4 inline-flex items-center gap-2 rounded-md"
                        href="#"
                      >
                        <span className="sr-only">Next</span>
                        <span aria-hidden="true" onClick={() => setCurrentPage(currentPage + 1)}>»</span>
                      </a>
                    </nav>

                  </div>
                }
              </div>
            </div>
          </section>
        </div >
      </div >
    </>
  );
}

export default Event;