import React from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { TextField } from '@mui/material';

const DatePicker = ({ label, name, value, onChange }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiDatePicker
                required
                label={label}
                value={value}
                name={name}
                onChange={(date) => { onChange(name, date) }}
                renderInput={(props) => <TextField fullWidth
                    {...props} />} // Replace TextField with input
            />
        </LocalizationProvider>
    );
};

export default DatePicker;
